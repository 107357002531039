import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AddbookMark, GetPopularClasses, RemovebookMark } from '../../../service/service';
// import { Disclosure } from '@headlessui/react'
// import arrowUp from '../../../assets/arrowUp.svg'
import { BookMarkIcon, BookMarkIconActive } from '../../../assets/icon-file';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { ColorRing } from 'react-loader-spinner';
function Popular() {
    const userData = useSelector((state) => state.userdata.userdata)
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isLogedin, setIsLogedIn] = useState(false)
    const [bookmarkLoading, setBookmarkLoading] = useState({ loadingId: null });
    useEffect(() => {

        GetPopularClasses().then((result) => {
            if (result.status) {
                setData(result.data)
                setLoading(false)
            }
        })
        if (userData) {

            setIsLogedIn(true)
        }
        else {
            setIsLogedIn(false)
        }
    }, [])
    const GotoChai = () => {
        navigate('/chai-club')
    }
    const OpenClass = (obj) => {

        const data = obj;
        navigate(`/${data.custom_permalink ?? data.slug}`);
    }
    function toggleIsMarked(itemId) {
        const updatedData = data.map(item => {
            if (item.id === itemId) {
                const data = {
                    class_id: item.id
                }
                setBookmarkLoading({ loadingId: item.id })
                if (item.is_marked === 1) {

                    RemovebookMark(data).then((result) => {
                        if (result.status) {
                            setBookmarkLoading({ loadingId: null })
                        }
                    })
                    return { ...item, is_marked: 0 };
                } else {

                    AddbookMark(data).then((result) => {
                        if (result.status) {
                            setBookmarkLoading({ loadingId: null })
                        }
                    })
                    return { ...item, is_marked: 1 };
                }
            }
            return item;
        });

        setData(updatedData);
    }
    return (
        <section id='popular-section' className='bg-primaryDark text-whiteGrey px-2 xs:px-5 py-8 lg:py-[60px]'>
            <div className="2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto">
                <h2 className='text-2xl lg:text-[40px] font-extrabold leading-[normal] text-center'>
                    Popular
                </h2>
                <div className='text-sm font-normal leading-[25.5px] text-center pt-3 lg:text-lg lg:leading-[28px] lg:font-semibold max-w-[670px] mx-auto'>
                    A comprehensive audio and video archive featuring individual lectures, short series, and elaborately detailed series
                </div>
                <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>

                    {
                        loading ?
                            <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                                <div><Skeleton height={80} /></div>
                                <div><Skeleton height={80} /></div>
                                <div><Skeleton height={80} /></div>
                            </div>
                            :
                            data?.slice(0, 3).map((obj) => (
                                <div key={obj.id} className='rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px] flex justify-between'>
                                    <div onClick={() => OpenClass(obj)} className='cursor-pointer pl-3 sm:pl-6 flex items-center justify-center gap-x-2 sm:gap-x-4'>
                                        <div className='h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] px-6 bg-whiteGrey flex items-center justify-center rounded-lg'>
                                            <span>
                                                <svg className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                                    <g clipPath="url(#clip0_326_587)">
                                                        <path d="M25.3333 20V5.33334C25.3324 5.13306 25.2864 4.93557 25.1987 4.75552C25.111 4.57548 24.9838 4.4175 24.8267 4.29334C24.6718 4.16803 24.4908 4.07903 24.2969 4.03289C24.1031 3.98674 23.9014 3.98461 23.7067 4.02667L11.7067 6.69334C11.4104 6.76014 11.1459 6.92607 10.9568 7.16368C10.7676 7.40128 10.6653 7.69633 10.6667 8V19.12C9.75645 18.6903 8.73143 18.5673 7.74539 18.7695C6.75934 18.9717 5.86545 19.4882 5.19779 20.2414C4.53014 20.9947 4.12473 21.9441 4.04238 22.9473C3.96004 23.9505 4.20519 24.9533 4.74107 25.8054C5.27695 26.6574 6.07467 27.3127 7.01454 27.673C7.95441 28.0333 8.98576 28.0791 9.95388 27.8036C10.922 27.5281 11.7747 26.9461 12.384 26.1449C12.9933 25.3437 13.3264 24.3665 13.3333 23.36C13.3471 23.1291 13.3471 22.8976 13.3333 22.6667V9.06667L22.6667 7V16.4533C21.7559 16.0234 20.7303 15.9005 19.7438 16.1031C18.7572 16.3058 17.8631 16.823 17.1956 17.5772C16.528 18.3313 16.1232 19.2817 16.0419 20.2855C15.9606 21.2894 16.2071 22.2925 16.7445 23.1443C17.2819 23.996 18.0812 24.6505 19.0222 25.0093C19.9632 25.3681 20.9953 25.4119 21.9634 25.1342C22.9314 24.8564 23.7833 24.2721 24.3909 23.4689C24.9986 22.6657 25.3293 21.6871 25.3333 20.68C25.346 20.4535 25.346 20.2265 25.3333 20Z" fill="#161E2E" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_326_587">
                                                            <rect width="32" height="32" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </span>
                                        </div>
                                        <div className='text-primaryDark'>
                                            <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{obj.title}</div>
                                            <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{obj.description}</div>
                                        </div>
                                    </div>
                                    <div className='flex items-center justify-center pr-4 sm:pr-6 gap-x-4 sm:gap-x-8'>
                                        <div className='cursor-pointer'>
                                            {

                                                isLogedin &&
                                                <>
                                                {
                                                    bookmarkLoading.loadingId === obj.id ?
                                                        <div>
                                                            <ColorRing
                                                                visible={true}
                                                                height="30"
                                                                width="30"
                                                                ariaLabel="blocks-loading"
                                                                wrapperStyle={{}}
                                                                wrapperClass="blocks-wrapper"
                                                                colors={['#186CCE', '#186CCE', '#186CCE', '#186CCE', '#186CCE']}
                                                            />
                                                        </div>
                                                        :
                                                        <span
                                                            onClick={() => toggleIsMarked(obj.id)}
                                                            className='cursor-pointer '
                                                        >

                                                            {

                                                                obj.is_marked == 0 ?

                                                                    <BookMarkIcon className='w-[20px] h-[20px] sm:w-[24px] sm:h-[24px]' />
                                                                    :
                                                                    <BookMarkIconActive className='w-[20px] h-[20px] sm:w-[24px] sm:h-[24px]' />
                                                            }
                                                        </span>
                                                }
                                            </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                    }

                </div>
                <button
                    type="button"
                    onClick={GotoChai}
                    className="flex gap-x-1 h-10 mx-auto mt-2 lg:h-12 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-8 py-1.5 text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                >
                    Join our Chai Club
                    <span aria-hidden="true" className='text-[13px]'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M17.92 11.62C17.8724 11.4973 17.801 11.3851 17.71 11.29L12.71 6.29C12.6168 6.19676 12.5061 6.1228 12.3842 6.07234C12.2624 6.02188 12.1319 5.99591 12 5.99591C11.7337 5.99591 11.4783 6.1017 11.29 6.29C11.1968 6.38324 11.1228 6.49393 11.0723 6.61575C11.0219 6.73758 10.9959 6.86814 10.9959 7C10.9959 7.2663 11.1017 7.5217 11.29 7.71L14.59 11H7C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H14.59L11.29 16.29C11.1963 16.383 11.1219 16.4936 11.0711 16.6154C11.0203 16.7373 10.9942 16.868 10.9942 17C10.9942 17.132 11.0203 17.2627 11.0711 17.3846C11.1219 17.5064 11.1963 17.617 11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L17.71 12.71C17.801 12.6149 17.8724 12.5028 17.92 12.38C18.02 12.1365 18.02 11.8635 17.92 11.62Z" fill="#F4FBF7" />
                        </svg>
                    </span>
                </button>
            </div>
        </section>
    )
}

export default Popular