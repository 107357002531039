import regexLibrary from "./regularExpressions";
const { strongTagsAndText, htmlTags, numbersPeriodFormat, parenthesesAndText, nonAlphaNumerical, nonLowerAlphaNumerical, pausesAndStops, textBeforeTags, textBetweenTags, textAfterTags } = regexLibrary;

export default function sanitizeDescription({title, description, type = null}) {
  let sanitizedDescription = description;
  // removing title from description - titles and similar info is found in strong tags
  const strongTags = description.match(strongTagsAndText);

  if (numbersPeriodFormat.test(title.split(" ")[0])) {
      title = title.slice(3)
  }
  const titleLetters = title.replace(parenthesesAndText, '').replace(nonAlphaNumerical, '').toUpperCase();
  const getTextLetters = (text) => {
        let textLetters = text.replace(htmlTags, '')
        if (numbersPeriodFormat.test(textLetters.split(" ")[0])) {
            textLetters = textLetters.slice(3)
        }
        return textLetters;
  };
  for (let i = 0; i < strongTags?.length; i++) {
      const strongTextLetters = getTextLetters(strongTags[i]);
      const textLettersArray = strongTextLetters.split(pausesAndStops)
      for (let i = 0; i < textLettersArray.length; i++) {
          if (textLettersArray[i].replace(parenthesesAndText, '').replace(nonAlphaNumerical, '').toUpperCase() === titleLetters) {
            sanitizedDescription = sanitizedDescription.replace(strongTagsAndText, "");
          }
      }
  }
  const textArray = getTextBetweenTags(sanitizedDescription);
  const textArrayCleaned = textArray.filter(element => element.length)
  if (textArrayCleaned?.length) {
      // loops through array to check for title and if any parts have an underscore (meaning it's a link)
      for (let i = 0; i < textArrayCleaned.length; i++) {
          const currentDescriptionLetters = textArrayCleaned[i].replace(parenthesesAndText, '').replace
          (nonAlphaNumerical, '').toUpperCase();
          if (currentDescriptionLetters === titleLetters || textArrayCleaned[i].includes("_")) {
              textArrayCleaned.splice(i, 1);
          } 
      }
  }
  // type category's descriptions are text (not html) and begin with the title, then sometimes a period followed by the actual description
  if (type === "category") {
      if (description.includes("click") || description.includes("Click")) {
          const newDescription = ""
          return newDescription;
      }
      // getting lengths without whitespace in case of typo
      const titleLetters = title.replaceAll(" ", "");
      const descriptionLetters = description.replaceAll(" ", "");
      const descriptionTitleLetters = descriptionLetters.slice(0, titleLetters.length);
      //regex checks that the character after the title is not text (a letter or number)
      if (nonLowerAlphaNumerical.test(descriptionLetters.charAt(descriptionTitleLetters.length)) && descriptionTitleLetters === titleLetters) {
          // removes title from description plus one character in case of punctuation
          const newDescription = description.slice(title.length + 1)
          return newDescription;
      }
  } 
  const initialValue = "";
  const formattedText = textArrayCleaned.reduce(
    (text, phrase) => {
        let segment = phrase
        if (phrase.charAt(phrase.length -1) !== "." && phrase !== textArrayCleaned[textArrayCleaned.length - 1]) {
           segment = `${phrase} - `
        } else {
            segment = `${phrase} `
        }
        return text + segment
    },
    initialValue
  )
return formattedText;
}

function getTextBetweenTags(htmlString) {
  // Match text content between tags
  const textArray = [];

  // Capture text before the first tag
  const textBeforeFirstTagMatch = htmlString.match(textBeforeTags);
  if (textBeforeFirstTagMatch && textBeforeFirstTagMatch[0].trim()) {
      textArray.push(textBeforeFirstTagMatch[0].trim());
  }

  let match;
  
  // Capture text between tags
  while ((match = textBetweenTags.exec(htmlString)) !== null) {
      textArray.push(match[1].trim());
  }

  // Capture text after the last tag
  const textAfterLastTagMatch = htmlString.match(textAfterTags);
  if (textAfterLastTagMatch && textAfterLastTagMatch[1].trim()) {
      textArray.push(textAfterLastTagMatch[1].trim());
  }

  return textArray;
}