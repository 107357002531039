import React, { useState, useEffect } from 'react'
import { ColorRing } from 'react-loader-spinner';
import { GetDailyTanya } from '../../../service/service';
import Header from '../../header/Header';
import Banner from '../../banner/Banner';
import Footer from '../../footer/Footer';
import { Link } from 'react-router-dom';

function DailyTanya() {

    const currentPath = window.location.pathname;

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({});
    const [dataArray, setDataArray] = useState([]);
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);

        // Optionally, you can also add a listener for navigation changes
        const handleScrollToTop = () => {
            window.scrollTo(0, 0);
        };

        // Attach the listener to the "beforeunload" event
        window.addEventListener('beforeunload', handleScrollToTop);

        // Clean up the listener when the component unmounts
        return () => {
            window.removeEventListener('beforeunload', handleScrollToTop);
        };
    }, []);
    useEffect(() => {
        setLoading(true)
        if (currentPath === '/daily-tanya') {
            const data = {
                name: 'Tanya'
            }
            ShowDailyTanya(data)
        }
        else if (currentPath === '/daily-hayom-yom') {
            const data = {
                name: 'Hayom Yom'
            }
            ShowDailyTanya(data)
        }
    }, [])
    const ShowDailyTanya = (data) => {

        GetDailyTanya(data).then((result) => {
            if (result.status) {
                // console.log(result?.data[0])
                const classItem = result?.data[0];
                // console.log(result.data)
                if (result.data.length > 0) {
                    setDataArray(result.data)
                }
                setData(classItem)
                setLoading(false)

            }
        })
    }

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate

    }
    const downloadFile = (fileUrl) => {
        const a = document.createElement('a');
        a.href = fileUrl;
        a.target = '_blank';
        a.click();
    };

    return (
        <>
            <Header />
            <Banner title={dataArray.length > 1 ? '' : data?.title} />
            {
                loading ?
                    <div className='flex justify-center min-h-[40vh]'>
                        <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC']}
                        />
                    </div>
                    :
                    <>
                        {
                            dataArray.length > 1 ?
                                <>
                                    {
                                        dataArray.length > 0 ?
                                            <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px] min-h-[40vh]'>
                                                {
                                                    dataArray.map((data) => (
                                                        <div key={data.id} className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
                                                            <h2 className='text-primaryDark leading-[normal] text-[22px] md:text-[40px] font-extrabold'>
                                                                {data?.title}
                                                            </h2>
                                                            <div className='text-primaryDark text-sm font-semibold opacity-50 mb-3'>
                                                                {data?.created_at && `Posted : ${formatDate(data?.is_publish_date ?? data?.created_at)}`}
                                                            </div>

                                                            {
                                                                data?.block?.map((obj) => (
                                                                    <div key={obj.id} className='mb-10'>

                                                                        {
                                                                            obj.type === 'Paragraph' &&
                                                                            <div className='text-primaryDark text-lg font-semibold'>
                                                                                {obj.description}
                                                                            </div>
                                                                        }
                                                                        {
                                                                            obj.type === 'Video' &&
                                                                            <div>
                                                                                <video className="rounded-lg w-full bg-whiteGrey" controls
                                                                                // onPlay={() => handleMediaPlayback(obj.id, 'Video')}
                                                                                >
                                                                                    <source src={obj.url} type="video/mp4" />
                                                                                </video>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            obj.type === 'Audio' &&
                                                                            <div className='bg-[#f1f3f4] rounded-lg'>
                                                                                <audio controls className="w-full"
                                                                                // onPlay={() => handleMediaPlayback(obj.id, 'Audio')}
                                                                                >
                                                                                    <source src={obj.url} type="audio/ogg" />
                                                                                    <source src={obj.url} type="audio/mpeg" />
                                                                                    Your browser does not support the audio tag.
                                                                                </audio>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            obj.type === 'Documents' &&

                                                                            <>


                                                                                <object data={obj.url} type="application/pdf" width="100%" height="500px">
                                                                                    <p>Unable to display PDF file. <a href={obj.url}>Download</a> instead.</p>
                                                                                </object>
                                                                                <div className='flex justify-center py-4'>
                                                                                    <button className="flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-3xl border-primaryBlue border-[1px] hover:bg-opacity-[0.9]  text-sm md:text-base font-semibold leading-7 text-primaryBlue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                                                        onClick={() => downloadFile(obj.url)}>
                                                                                        Download pdf
                                                                                        <span>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                                <path d="M12.3798 17.92C12.5026 17.8724 12.6147 17.801 12.7098 17.71L17.7098 12.71C17.8031 12.6168 17.877 12.5061 17.9275 12.3842C17.9779 12.2624 18.0039 12.1319 18.0039 12C18.0039 11.7337 17.8981 11.4783 17.7098 11.29C17.6166 11.1968 17.5059 11.1228 17.3841 11.0723C17.2622 11.0219 17.1317 10.9959 16.9998 10.9959C16.7335 10.9959 16.4781 11.1017 16.2898 11.29L12.9998 14.59L12.9998 7C12.9998 6.73478 12.8945 6.48043 12.7069 6.29289C12.5194 6.10536 12.265 6 11.9998 6C11.7346 6 11.4802 6.10536 11.2927 6.29289C11.1052 6.48043 10.9998 6.73478 10.9998 7L10.9998 14.59L7.70981 11.29C7.61685 11.1963 7.50625 11.1219 7.38439 11.0711C7.26253 11.0203 7.13183 10.9942 6.99981 10.9942C6.8678 10.9942 6.7371 11.0203 6.61524 11.0711C6.49338 11.1219 6.38278 11.1963 6.28982 11.29C6.19609 11.383 6.12169 11.4936 6.07092 11.6154C6.02015 11.7373 5.99402 11.868 5.99402 12C5.99402 12.132 6.02015 12.2627 6.07092 12.3846C6.12169 12.5064 6.19609 12.617 6.28982 12.71L11.2898 17.71C11.3849 17.801 11.4971 17.8724 11.6198 17.92C11.8633 18.02 12.1364 18.02 12.3798 17.92Z" fill="#186CCE" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    </button>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        {
                                                                            obj.type === 'Images' &&

                                                                            <>
                                                                                <div className='flex justify-center rounded-lg'>
                                                                                    <img src={obj.url} alt="class" />
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        {
                                                                            obj.type === 'Custom HTML' &&

                                                                            <>
                                                                                <div dangerouslySetInnerHTML={{ __html: obj.html }} />
                                                                            </>
                                                                        }
                                                                    </div>
                                                                ))
                                                            }

                                                            {
                                                                data?.tags?.length > 0 &&
                                                                <div className='flex flex-wrap gap-3 mt-6 mb-4'>
                                                                    {
                                                                        data?.tags?.map((tag, index) => (
                                                                            <Link key={index + 'item'} to={`/tag/${tag?.slug}`} className='py-2 px-6 bg-primaryDark rounded-md text-base text-whiteGrey'>
                                                                                {tag.name}
                                                                            </Link>
                                                                        ))
                                                                    }
                                                                </div>


                                                            }
                                                        </div>
                                                    ))
                                                }


                                            </section>
                                            :
                                            <div className='text-primaryDark text-lg font-bold text-center pt-10 h-[50vh]'>
                                                No Data Found
                                            </div>
                                    }
                                </>
                                :
                                <>
                                    {
                                        data ?
                                            <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px] min-h-[40vh]'>
                                                <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
                                                    <div className='text-primaryDark text-sm font-semibold opacity-50 mb-3'>
                                                        {data?.created_at && `Posted : ${formatDate(data?.is_publish_date ?? data?.created_at)}`}
                                                    </div>

                                                    {
                                                        data?.block?.map((obj) => (
                                                            <div key={obj.id} className='mb-10'>
                                                                {
                                                                    obj.type === 'Paragraph' &&
                                                                    <div className='text-primaryDark text-lg font-semibold'>
                                                                        {obj.description}
                                                                    </div>
                                                                }
                                                                {
                                                                    obj.type === 'Video' &&
                                                                    <div>
                                                                        <video className="rounded-lg w-full bg-whiteGrey" controls
                                                                        // onPlay={() => handleMediaPlayback(obj.id, 'Video')}
                                                                        >
                                                                            <source src={obj.url} type="video/mp4" />
                                                                        </video>
                                                                    </div>
                                                                }
                                                                {
                                                                    obj.type === 'Audio' &&
                                                                    <div className='bg-[#f1f3f4] rounded-lg'>
                                                                        <audio controls className="w-full"
                                                                        // onPlay={() => handleMediaPlayback(obj.id, 'Audio')}
                                                                        >
                                                                            <source src={obj.url} type="audio/ogg" />
                                                                            <source src={obj.url} type="audio/mpeg" />
                                                                            Your browser does not support the audio tag.
                                                                        </audio>
                                                                    </div>
                                                                }
                                                                {
                                                                    obj.type === 'Documents' &&

                                                                    <>


                                                                        <object data={obj.url} type="application/pdf" width="100%" height="500px">
                                                                            <p>Unable to display PDF file. <a href={obj.url}>Download</a> instead.</p>
                                                                        </object>
                                                                        <div className='flex justify-center py-4'>
                                                                            <button className="flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-3xl border-primaryBlue border-[1px] hover:bg-opacity-[0.9]  text-sm md:text-base font-semibold leading-7 text-primaryBlue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                                                onClick={() => downloadFile(obj.url)}>
                                                                                Download pdf
                                                                                <span>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                        <path d="M12.3798 17.92C12.5026 17.8724 12.6147 17.801 12.7098 17.71L17.7098 12.71C17.8031 12.6168 17.877 12.5061 17.9275 12.3842C17.9779 12.2624 18.0039 12.1319 18.0039 12C18.0039 11.7337 17.8981 11.4783 17.7098 11.29C17.6166 11.1968 17.5059 11.1228 17.3841 11.0723C17.2622 11.0219 17.1317 10.9959 16.9998 10.9959C16.7335 10.9959 16.4781 11.1017 16.2898 11.29L12.9998 14.59L12.9998 7C12.9998 6.73478 12.8945 6.48043 12.7069 6.29289C12.5194 6.10536 12.265 6 11.9998 6C11.7346 6 11.4802 6.10536 11.2927 6.29289C11.1052 6.48043 10.9998 6.73478 10.9998 7L10.9998 14.59L7.70981 11.29C7.61685 11.1963 7.50625 11.1219 7.38439 11.0711C7.26253 11.0203 7.13183 10.9942 6.99981 10.9942C6.8678 10.9942 6.7371 11.0203 6.61524 11.0711C6.49338 11.1219 6.38278 11.1963 6.28982 11.29C6.19609 11.383 6.12169 11.4936 6.07092 11.6154C6.02015 11.7373 5.99402 11.868 5.99402 12C5.99402 12.132 6.02015 12.2627 6.07092 12.3846C6.12169 12.5064 6.19609 12.617 6.28982 12.71L11.2898 17.71C11.3849 17.801 11.4971 17.8724 11.6198 17.92C11.8633 18.02 12.1364 18.02 12.3798 17.92Z" fill="#186CCE" />
                                                                                    </svg>
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                }
                                                                {
                                                                    obj.type === 'Images' &&

                                                                    <>
                                                                        <div className='flex justify-center rounded-lg'>
                                                                            <img src={obj.url} alt="class" />
                                                                        </div>
                                                                    </>
                                                                }
                                                                {
                                                                    obj.type === 'Custom HTML' &&

                                                                    <>
                                                                        <div dangerouslySetInnerHTML={{ __html: obj.html }} />
                                                                    </>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                    {
                                                        data?.tags?.length > 0 &&
                                                        <div className='flex flex-wrap gap-3 mt-6 mb-4'>
                                                            {
                                                                data?.tags?.map((tag, index) => (
                                                                    <Link key={index + 'item'} to={`/tag/${tag?.slug}`} className='py-2 px-6 bg-primaryDark rounded-md text-base text-whiteGrey'>
                                                                        {tag.name}
                                                                    </Link>
                                                                ))
                                                            }
                                                        </div>


                                                    }
                                                </div>
                                            </section>
                                            :
                                            <div className='text-primaryDark text-lg font-bold text-center pt-10 h-[50vh]'>
                                                No Data Found
                                            </div>
                                    }
                                </>
                        }
                    </>
            }

            <Footer />
        </>
    )
}

export default DailyTanya