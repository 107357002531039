import React, { Fragment, useState } from 'react'
import Fuse from 'fuse.js'
import { Disclosure, Listbox, Transition, Dialog, Combobox } from '@headlessui/react'
import arrowUp from '../../assets/arrowUp.svg'
import upload from '../../assets/Upload-icon.svg'
import CheckboxTree from '../NestedCheckbox/NestedCheckbox';
import { useDispatch, useSelector } from 'react-redux';
// import { MultiSelect } from "react-multi-select-component";
import { updateAdminUser } from '../../store/slices/AdminUserSlice';
import { useEffect } from 'react';
import MediaGallaryModal from '../MediaGallary/MediaGallaryModal';
import { DropdownIcon, ReplaceIconWhite } from '../../assets/icon-file';
import { Tooltip } from 'react-tooltip';
import { AdminHeaderSearch, AdminListTagsType, AdminTagDropdown, CategoriesDropdown, SeriesDropdown } from '../../service/service';
import PaginateMultiSelect from '../../ReuseableComponents/PaginateMultiSelect';
import { CheckIcon } from '@heroicons/react/24/outline';

function TabSection1({
    // selected, setSelected,
    formData, tabtext, setfeatueFile, EditorType }) {

    const baseUrl = window.location.origin;

    // const ClassCategory = useSelector((state) => state.adminUser.category)
    const [ClassCategory, setClassCategory] = useState([])
    // const seriesData = useSelector((state) => state.adminUser.series)
    const [seriesData, setseriesData] = useState([])
    // const DropdownOptions = useSelector((state) => state.adminUser.TagsListData)
    const MostUsedTags = useSelector((state) => state.adminUser.mostUsedTags)
    const dispatch = useDispatch();
    const [textToCopy, setTextToCopy] = useState(`${baseUrl}${tabtext === 'Series' ? `/${tabtext.toLowerCase()}` : ''}/${formData.perma_link !== '' ? formData.perma_link : formData.slug}`)
    const [isCopied, setIsCopied] = useState(false);
    const [isOpenMedia, setIsOpenMedia] = useState(false)
    const [SelectedFilterData, setFilterData] = useState({
        categories: [],
        tags: [],
    })
    const [tagTypes, setTagTypes] = useState([])
    useEffect(() => {
        const TagidsArray = SelectedFilterData.tags?.map(item => item.id);
        //   console.log(TagidsArray)
        dispatch(updateAdminUser({
            editorFormData: {
                ...formData,
                tags: TagidsArray
            }
        }));

    }, [SelectedFilterData])
    // const [searchTagType, setSearchTagType] = useState("");

    useEffect(() => {
        AdminListTagsType(0, 2000)
            .then((result) => {

                if (result.status) {
                    // console.log([{id:-1,name:'Any'},...result.data.data])
                    setTagTypes([{id:-1,name:'Any'},...result.data.data])
                    setSelected({id:-1,name:'Any'})
                }
            })
        fetchMoreData()
        fetchMoreSeriesData()
    }, [])

    const [query, setQuery] = useState('')

    const filteredPeople =
        query === ''
            ? tagTypes
            : tagTypes.filter((obj) =>
                obj.name
                    .toLowerCase()
                    .replace(/\s+/g, '')
                    .includes(query.toLowerCase().replace(/\s+/g, ''))
            )

    // const filteredTagType = tagTypes?.filter((item) => {

    //     return item.name?.toLowerCase().includes(searchTagType?.toLowerCase());
    // });
    const copyMessage = () => {
        navigator.clipboard.writeText(textToCopy);
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    }
    const handleCheckboxChange = (checkedItems) => {
        // Handle changes to the checked items here
        // console.log("Checked Items:", checkedItems);
        dispatch(updateAdminUser({
            editorFormData: {
                ...formData,
                categories: checkedItems
            }
        }));
    };
    const [searchCategory, setSearchCategory] = useState("");
    // const filterData = (items, query) => {
    //     return items?.filter((item) => {
    //         const isMatch = item.name?.toLowerCase().includes(query.toLowerCase());
    //         const hasMatchingChildren = item.children && item.children.length > 0 && filterData(item.children, query).length > 0;

    //         return isMatch || hasMatchingChildren;
    //     });
    // };

    const filterData = (items, query) => {
        if (!query) return items; // If no query, return all items

        const normalizeText = (text) => {
            return text.replace(/[^\w\s]|_/g, "")
                       .replace(/\s+/g, " ");
          };

          // Prepare items by normalizing text
        //   const preparedItems = items.map(item => ({
        //     ...item,
        //     name: normalizeText(item.name) // Assuming you're searching the 'name' property
        //   }));

        const prepareItems = (currentItems) => {
            return currentItems.map(item => {
                // Normalize the name of the current item
                const normalizedItem = {
                    ...item,
                    normalized: normalizeText(item.name)
                };

                // Recursively prepare children if they exist
                if (item.children && item.children.length > 0) {
                    normalizedItem.children = prepareItems(item.children);
                }
                
                return normalizedItem;
            });
        };
        const preparedItems = prepareItems(items);


        // Options for Fuse.js
        const options = {
            includeScore: true, // Include the score in the result set (helpful for debugging)
            findAllMatches: true, // Find all matches regardless of location
            tokenize: true,
            matchAllTokens: false,
            tokenSeparator: / +/g, // Splits on spaces after normalization
            shouldSort: true,
            threshold: 0.4, // Adjust this value for strictness (0.0 = exact match, 1.0 = match anything)
            keys: ['normalized'] // Set up which keys you want to search in
        };
    
        const parentNotInResults = (children, currentParentIds) =>  !currentParentIds.includes(children[0].parent_id) // Avoid duplicating the child in it's parent and separately

        // Format and sort search results so parents will always display in order of match accuracy
        const formatAndSortResults = (searchResults) => {
            const formattedResults = []
            const parentCategory = {}
            // Loop through results to group children with their parent
            for (let i = 0; i < searchResults.length; i++) {
                if (!searchResults[i].parent) {
                    formattedResults.push(searchResults[i])
                } else {
                    if (!parentCategory[searchResults[i].parent_id]) {
                        parentCategory[searchResults[i].parent_id] = searchResults[i].parent
                        parentCategory[searchResults[i].parent_id].children = [searchResults[i]]
                    } else {
                        parentCategory[searchResults[i].parent_id].children.push(searchResults[i])
                    }
                }
            }
            // Sort children within their parent and push the parent object to the results array
            for (const property in parentCategory) {
                parentCategory[property].children.sort((a, b) => a.score - b.score);
                parentCategory[property].score = parentCategory[property].children[0].score
                formattedResults.push(parentCategory[property])
            }
            const sortedResults = formattedResults.sort((a, b) => a.score - b.score);
            return sortedResults
        }
        
        // Function to recursively search and collect items
        const searchItems = (currentItems) => {
            const currentParentIds = [];
            const fuse = new Fuse(currentItems, options); // Create a new Fuse instance for current items 
            let results = fuse.search(query).map(res => { // Search and map results to items
                currentParentIds.push(res.item.id)
                return { ...res.item, score: res.score}; // Add score to sort results later
            })
            //Recursively search in children if they exist
            currentItems.forEach(item => {
                if (item.children && item.children.length > 0 && parentNotInResults(item.children,currentParentIds)) {
                    item.children.forEach(child => child.parent = {id: item.id, name: item.name, disabled: true}) // Add disabled for parent to display disabled when not an actual match
                    results = results.concat(searchItems(item.children)); // Concatenate results of recursive search
                }
            });
            // return results;
            const formattedResults = formatAndSortResults(results);
            return formattedResults
        };
    
        // Start the recursive search from the top-level items
        return searchItems(preparedItems);
    };
    

    // const filterData = (items, query) => {
    //     if (!query) return items; // If no query, return all items
    //     // Options for Fuse.js
    //     const options = {
    //         includeScore: true, // Include the score in the result set (helpful for debugging)
    //         findAllMatches: true, // Find all matches regardless of location
    //         threshold: 0.4, // Adjust this value for strictness (0.0 = exact match, 1.0 = match anything)
    //         keys: ['name'] // Set up which keys you want to search in
    //     };
    
    //     const fuse = new Fuse(items, options); // Create a new Fuse instance
    
    //     // Use Fuse to search
    //     const result = fuse.search(query);
    
    //     // Map over the search results to get a list of matched items
    //     const matchedItems = result.map(res => res.item);
    
    //     // Filter out items that have matching children recursively
    //     return matchedItems.filter((item) => {
    //         const hasMatchingChildren = item.children && item.children.length > 0 && filterData(item.children, query).length > 0;
    //         return hasMatchingChildren || result.some(res => res.item === item);
    //     });
    // };
    const filteredCategory = filterData(ClassCategory, searchCategory)

    const handleSeriesCheckbox = (checkedItems) => {

        dispatch(updateAdminUser({
            editorFormData: {
                ...formData,
                series: checkedItems
            }
        }));
    };
    const [searchQuery, setSearchQuery] = useState("");
    // const filteredData = seriesData?.filter((item) => {

    //     return item.title.toLowerCase().includes(searchQuery.toLowerCase());
    // });

    useEffect(() => {
        const getData = setTimeout(() => {
        //   if (searchQuery !== '') {
        //     performSearch(searchTerm);
        //   }
          if (searchQuery === '') {
                setHasMoreSeries(true)
                setSeriesPage(1)
          }
        SeriesDropdown(0, 1000,searchQuery).then((result) => {
            if (result.status) {
                 setseriesData(result?.data?.data);
              }
        })
            .catch((error) => {
                console.error("Error fetching data: ", error);
            });

        }, 1000)
      
        return () => clearTimeout(getData)
      }, [searchQuery])

    const [ selectedDate, setSelectedDate] = useState(formData.date);
    const [ selectedTime, setSelectedTime] = useState(formData.time);
    const [dateInputError, setDateInputError] = useState("");
    const [timeInputError, setTimeInputError] = useState("");
    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name === 'perma_link') {
            dispatch(updateAdminUser({
                editorFormData: {
                    ...formData,
                    [name]: value.replace(/[^a-zA-Z0-9-]+/g, '-').toLowerCase()
                }
            }));
            setTextToCopy(`${baseUrl}${tabtext === 'Series' ? `/${tabtext.toLowerCase()}` : ''}/${formData.perma_link !== '' ? formData.perma_link : formData.slug}`)
        } else {
            if (name === "date" && (value.split("-")[0]).length > 4) {
                return
            }
            name === 'time' ? setSelectedTime(value + ':00') : setSelectedDate(value);
        }
    }

    const handleTags = (selectedItems) => {

        const uniqueArray = [...new Set(selectedItems)];

        setSelectMostUsed(uniqueArray)

    }
    const visibilty = [
        { name: 'public' },
        { name: 'private' },

    ]
    const [selectedOption, setSelectedOption] = useState({ name: formData.visibility })
    const handleVisibilty = (item) => {
        // console.log(item)
        setSelectedOption(item)
        dispatch(updateAdminUser({
            editorFormData: {
                ...formData,
                visibility: item.name
            }
        }));
    }
    const [isOpen, setIsOpen] = useState(false)

    function closeModal({ date = null, time = null }) {
        setIsOpen(false)
        if (date && time) {
            setSelectedDate(date);
            setSelectedTime(time);
        }
        setDateInputError("");
        setTimeInputError("");
    }
    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return { date: `${year}-${month}-${day}`, time: `${hours}:${minutes}:00` }
    }
    function openModal() {
        setIsOpen(true)
    }

    const handlePublishTime = (param) => {
        if (param === 'now') {
            const dateTime = getCurrentDateTime()
            closeModal({ date: dateTime.date, time: dateTime.time })
            dispatch(updateAdminUser({
                editorFormData: {
                    ...formData,
                    is_publish: 1,
                    date: dateTime.date,
                    time: dateTime.time
                }
            }));
        }
        else {
            const timeFormat = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/
            const isValid = timeFormat.test(selectedTime.slice(0,5));
            if (!isValid) {
                setTimeInputError("Invalid time entry");
                return
            }
            setTimeInputError("");
            const dateInput = selectedDate
            if (parseInt(dateInput.split("-")[0]) < 2017) {
                setDateInputError("Year may be no earlier than 2017");
                return;
            }
            const dateObj = new Date(dateInput);
            if (dateObj.toString() === "Invalid Date") {
                setDateInputError("Invalid date entry");
                return
            };
            setDateInputError("");
            dispatch(updateAdminUser({
                editorFormData: {
                    ...formData,
                    date: selectedDate,
                    time: selectedTime,
                    is_publish: 0,
                }
            }));
            closeModal({})
        }
    }

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "long", day: "numeric" };
        const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
        return formattedDate;
    };

    const formatTime = (dateString) => {
        const options = { hour: "numeric", minute: "numeric", hour12: true };
        const formattedTime = new Date(dateString).toLocaleTimeString(undefined, options);
        return formattedTime.toLowerCase();
    };
    //   const dateTimeString = "2023-09-08T16:39:00";
    const dateTimeString = `${formData.date ?? formData.is_publish_date}T${formData.time ?? formData.is_publish_time}`;

    const [selectMostUsed, setSelectMostUsed] = useState([])
    const [selectedFile, setSelectedFile] = useState(null);
    const [selected, setSelected] = useState({})

  
    

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setfeatueFile(file)

        }

    };

    const selectedMedia = (item) => {
        setIsOpenMedia(false)
        setfeatueFile(item.url)
        // console.log(item.url)
        dispatch(updateAdminUser({
            editorFormData: {
                ...formData,
                feature_image: item.url,
            }
        }));
    }
    const ReplaceImage = () => {
        // console.log()
        dispatch(updateAdminUser({
            editorFormData: {
                ...formData,
                feature_image: null,
            }
        }));
        setfeatueFile(null)
        setSelectedFile(null)
    }
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0); // Track pagination

    const fetchMoreData = () => {
        CategoriesDropdown(page, 2000).then((result) => {
            if (result.status) {
                if (result?.data?.length === 0) {
                    setHasMore(false); // No more data to load
                }
                else {


                    setClassCategory(prevItems => [...prevItems, ...result.data]);
                    // console.log(result.data)
                    setPage(page + 1); // Increment page for the next API call

                }

            }
        })
            .catch((error) => {
                console.error("Error fetching data: ", error);
            });

    };
    const [hasMoreSeries, setHasMoreSeries] = useState(true);
    const [Seriespage, setSeriesPage] = useState(0); // Track pagination

    const fetchMoreSeriesData = () => {
        SeriesDropdown(Seriespage, 1000).then((result) => {
            if (result.status) {
                if (result?.data?.data?.length === 0) {
                    setHasMoreSeries(false); // No more data to load
                }
                else {


                    setseriesData(prevItems => [...prevItems, ...result?.data?.data]);
                    // console.log(result.data)
                    setSeriesPage(Seriespage + 1); // Increment page for the next API call

                }

            }
        })
            .catch((error) => {
                console.error("Error fetching data: ", error);
            });

    };

    return (
        <>

            <div className='p-4'>
                <Disclosure defaultOpen>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex w-full justify-between text-left text-base text-primaryDark font-bold ">
                                <span>Summary</span>
                                <img
                                    src={arrowUp}
                                    className={`${open ? '' : 'rotate-180 transform'
                                        } h-5 w-5`}
                                />

                            </Disclosure.Button>
                            <Disclosure.Panel className="pt-4 pb-6 text-sm text-primaryDark">
                                <div className='space-y-3'>
                                    <div className="flex justify-between">
                                        <div>Visibility</div>
                                        <div className='text-primaryBlue relative'>
                                            <Listbox value={selectedOption} onChange={(item) => handleVisibilty(item)}>
                                                <Listbox.Button>
                                                    <span className="block truncate">{selectedOption.name}</span>
                                                </Listbox.Button>
                                                <Transition
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute right-0 mt-1 max-h-60 w-[100px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {visibilty.map((person, personIdx) => (
                                                            <Listbox.Option
                                                                key={personIdx}
                                                                className={({ active }) =>
                                                                    `relative cursor-default select-none py-2  px-4 ${active ? 'bg-primaryBlue text-whiteGrey' : 'text-gray-900'
                                                                    }`
                                                                }
                                                                value={person}
                                                            >
                                                                {({ selected }) => (
                                                                    <>
                                                                        <span
                                                                            className={`block truncate ${selected ? 'font-semibold' : 'font-semibold'
                                                                                }`}
                                                                        >
                                                                            {person.name}
                                                                        </span>

                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </Transition>
                                            </Listbox>
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div>Publish</div>
                                        <div
                                            onClick={openModal}
                                            className='text-primaryBlue cursor-pointer flex flex-col'>
                                            {
                                                (!formData.id && (formData.date !== formData.defaultDate || formData.time !== formData.defaultTime)) || formData.id  ?
                                                    <>
                                                        <span>{formatDate(dateTimeString)}</span>
                                                        <span>{formatTime(dateTimeString)}</span>
                                                    </> :
                                                    'Immediately'
                                            }
                                        </div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div>URL</div>
                                        <div
                                            data-tooltip-id="my-tooltip"
                                            data-tooltip-content={textToCopy}
                                            className='text-primaryBlue flex items-center'>
                                            <div className='truncate max-w-[114px]'>
                                                <a href={textToCopy}
                                                    target='_blank'
                                                >
                                                    {textToCopy}
                                                </a>
                                            </div>

                                            <span className='cursor-pointer'
                                                onClick={() => copyMessage(textToCopy)}
                                            >
                                                <svg className='h-4' xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                    <g clipPath="url(#clip0_1326_6335)">
                                                        <path d="M7.28906 12H2.8125C1.77859 12 0.9375 11.1589 0.9375 10.125V3.77344C0.9375 2.73953 1.77859 1.89844 2.8125 1.89844H7.28906C8.32297 1.89844 9.16406 2.73953 9.16406 3.77344V10.125C9.16406 11.1589 8.32297 12 7.28906 12ZM2.8125 2.83594C2.29559 2.83594 1.875 3.25653 1.875 3.77344V10.125C1.875 10.6419 2.29559 11.0625 2.8125 11.0625H7.28906C7.80597 11.0625 8.22656 10.6419 8.22656 10.125V3.77344C8.22656 3.25653 7.80597 2.83594 7.28906 2.83594H2.8125ZM11.0391 8.95312V1.875C11.0391 0.841095 10.198 0 9.16406 0H3.96094C3.70203 0 3.49219 0.209839 3.49219 0.46875C3.49219 0.727661 3.70203 0.9375 3.96094 0.9375H9.16406C9.68097 0.9375 10.1016 1.35809 10.1016 1.875V8.95312C10.1016 9.21204 10.3114 9.42188 10.5703 9.42188C10.8292 9.42188 11.0391 9.21204 11.0391 8.95312Z" fill="#515F7B" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1326_6335">
                                                            <rect width="12" height="12" fill="white" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                {
                                                    isCopied &&

                                                    <div className="success-message">
                                                        Copied to clipboard!
                                                    </div>
                                                }
                                            </span>
                                        </div>
                                        <Tooltip
                                            id="my-tooltip"
                                            place="bottom-start"
                                            style={{ backgroundColor: "#161E2E", color: "#F9F9F9", maxWidth: '300px', zIndex: '1', wordBreak: 'break-all' }}
                                        />
                                    </div>
                                    <div>
                                        Custom Permalink
                                        <div className=' rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] mt-2 py-2 px-4'>
                                            <label htmlFor="permalink" className="block text-sm font-normal opacity-60 leading-5 text-primaryDark">
                                                {`${baseUrl}${tabtext === 'Series' ? `/${tabtext.toLowerCase()}` : ''}/`}

                                            </label>
                                            <div className="relative">
                                                <input
                                                    id="permalink"
                                                    name="perma_link"
                                                    type='text'
                                                    required
                                                    placeholder='Enter Custom permalink'
                                                    value={formData.perma_link}
                                                    onChange={handleChange}
                                                    className="placeholder:text-primaryDark block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-base sm:leading-normal font-normal"
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                {
                    EditorType !== 'page' &&
                    <>
                        <Disclosure defaultOpen>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex w-full justify-between text-left text-base text-primaryDark font-bold ">
                                        <span>Category</span>
                                        <img
                                            src={arrowUp}
                                            className={`${open ? '' : 'rotate-180 transform'
                                                } h-5 w-5`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="pt-4 pb-6 text-sm text-primaryDark">
                                        <div className='hidden lg:flex justify-between items-center border-[1px] border-[#D2D6DC] rounded-3xl pl-5 pr-2 w-full h-[40px] mb-4'>
                                            <input
                                                value={searchCategory}
                                                onChange={(e) => setSearchCategory(e.target.value)}
                                                className='bg-transparent outline-none' type="text" placeholder='Search' />
                                            <div className='flex items-center justify-center  rounded-full h-[31px] w-[31px] bg-primaryBlue'>
                                                <svg className='relative right-[-1px]' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.8086 12.8633C14.0547 13.1367 14.0547 13.5469 13.7812 13.793L13.0156 14.5586C12.7695 14.832 12.3594 14.832 12.0859 14.5586L9.37891 11.8516C9.24219 11.7148 9.1875 11.5508 9.1875 11.3867V10.9219C8.20312 11.6875 7 12.125 5.6875 12.125C2.54297 12.125 0 9.58203 0 6.4375C0 3.32031 2.54297 0.75 5.6875 0.75C8.80469 0.75 11.375 3.32031 11.375 6.4375C11.375 7.77734 10.9102 8.98047 10.1719 9.9375H10.6094C10.7734 9.9375 10.9375 10.0195 11.0742 10.1289L13.8086 12.8633ZM5.6875 9.9375C7.60156 9.9375 9.1875 8.37891 9.1875 6.4375C9.1875 4.52344 7.60156 2.9375 5.6875 2.9375C3.74609 2.9375 2.1875 4.52344 2.1875 6.4375C2.1875 8.37891 3.74609 9.9375 5.6875 9.9375Z" fill="white" />
                                                </svg>
                                            </div>
                                        </div>
                                        <CheckboxTree data={filteredCategory} onChange={handleCheckboxChange} value={formData.categories} hasMore={hasMore} fetchMoreData={fetchMoreData} />
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                        <Disclosure defaultOpen>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex w-full justify-between text-left text-base text-primaryDark font-bold ">
                                        <span>Series</span>
                                        <img
                                            src={arrowUp}
                                            className={`${open ? '' : 'rotate-180 transform'
                                                } h-5 w-5`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="pt-4 pb-2 text-sm text-primaryDark">
                                        <div className='hidden lg:flex justify-between items-center border-[1px] border-[#D2D6DC] rounded-3xl pl-5 pr-2 w-full h-[40px] mb-4'>
                                            <input
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                className='bg-transparent outline-none' type="text" placeholder='Search' />
                                            <div className='flex items-center justify-center  rounded-full h-[31px] w-[31px] bg-primaryBlue'>
                                                <svg className='relative right-[-1px]' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.8086 12.8633C14.0547 13.1367 14.0547 13.5469 13.7812 13.793L13.0156 14.5586C12.7695 14.832 12.3594 14.832 12.0859 14.5586L9.37891 11.8516C9.24219 11.7148 9.1875 11.5508 9.1875 11.3867V10.9219C8.20312 11.6875 7 12.125 5.6875 12.125C2.54297 12.125 0 9.58203 0 6.4375C0 3.32031 2.54297 0.75 5.6875 0.75C8.80469 0.75 11.375 3.32031 11.375 6.4375C11.375 7.77734 10.9102 8.98047 10.1719 9.9375H10.6094C10.7734 9.9375 10.9375 10.0195 11.0742 10.1289L13.8086 12.8633ZM5.6875 9.9375C7.60156 9.9375 9.1875 8.37891 9.1875 6.4375C9.1875 4.52344 7.60156 2.9375 5.6875 2.9375C3.74609 2.9375 2.1875 4.52344 2.1875 6.4375C2.1875 8.37891 3.74609 9.9375 5.6875 9.9375Z" fill="white" />
                                                </svg>
                                            </div>
                                        </div>
                                        <CheckboxTree data={seriesData} onChange={handleSeriesCheckbox} value={formData.series} hasMore={hasMoreSeries} fetchMoreData={fetchMoreSeriesData} />
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                        {/* <Disclosure defaultOpen>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex w-full justify-between text-left text-base text-primaryDark font-bold ">
                                        <span>Tag Types</span>
                                        <img
                                            src={arrowUp}
                                            className={`${open ? '' : 'rotate-180 transform'
                                                } h-5 w-5`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="pt-4 pb-6 text-sm text-primaryDark tagsTypeParent">
                                        <div className='hidden lg:flex justify-between items-center border-[1px] border-[#D2D6DC] rounded-3xl pl-5 pr-2 w-full h-[40px] mb-4'>
                                            <input
                                                value={searchTagType}
                                                onChange={(e) => setSearchTagType(e.target.value)}
                                                className='bg-transparent outline-none' type="text" placeholder='Search' />
                                            <div className='flex items-center justify-center  rounded-full h-[31px] w-[31px] bg-primaryBlue'>
                                                <svg className='relative right-[-1px]' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M13.8086 12.8633C14.0547 13.1367 14.0547 13.5469 13.7812 13.793L13.0156 14.5586C12.7695 14.832 12.3594 14.832 12.0859 14.5586L9.37891 11.8516C9.24219 11.7148 9.1875 11.5508 9.1875 11.3867V10.9219C8.20312 11.6875 7 12.125 5.6875 12.125C2.54297 12.125 0 9.58203 0 6.4375C0 3.32031 2.54297 0.75 5.6875 0.75C8.80469 0.75 11.375 3.32031 11.375 6.4375C11.375 7.77734 10.9102 8.98047 10.1719 9.9375H10.6094C10.7734 9.9375 10.9375 10.0195 11.0742 10.1289L13.8086 12.8633ZM5.6875 9.9375C7.60156 9.9375 9.1875 8.37891 9.1875 6.4375C9.1875 4.52344 7.60156 2.9375 5.6875 2.9375C3.74609 2.9375 2.1875 4.52344 2.1875 6.4375C2.1875 8.37891 3.74609 9.9375 5.6875 9.9375Z" fill="white" />
                                                </svg>
                                            </div>
                                        </div>
                                        <CheckboxTree data={filteredTagType} onChange={handleCheckboxChange} value={[]} />
                                        
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure> */}
                        <Disclosure defaultOpen>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex w-full justify-between text-left text-base text-primaryDark font-bold ">
                                        <span>Tags</span>
                                        <img
                                            src={arrowUp}
                                            className={`${open ? '' : 'rotate-180 transform'
                                                } h-5 w-5`}
                                        />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="pt-4 pb-2 text-sm text-primaryDark">
                                    <div className="">
                                            <div className='h-[85px] my-5 rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4'>
                                                <div className='flex gap-x-1 items-center'>
                                                    <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                                        Select Tag Type
                                                    </label>

                                                </div>
                                                <div className="">
                                                    <Combobox value={selected} onChange={setSelected}>
                                                        <div className="relative">
                                                            <div className="relative ">
                                                                <Combobox.Input
                                                                    className="w-full border-none h-[43px] bg-transparent py-1.5 pr-10 text-left focus:outline-none sm:text-lg sm:leading-normal font-semibold"
                                                                    displayValue={(person) => person.name}
                                                                    onChange={(event) => setQuery(event.target.value)}
                                                                />
                                                                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                                    <DropdownIcon
                                                                        aria-hidden="true"
                                                                    />
                                                                </Combobox.Button>
                                                            </div>
                                                            <Transition
                                                                as={Fragment}
                                                                leave="transition ease-in duration-100"
                                                                leaveFrom="opacity-100"
                                                                leaveTo="opacity-0"
                                                                afterLeave={() => setQuery('')}
                                                            >
                                                                <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 z-10 text-base  focus:outline-none sm:text-sm">
                                                                    {filteredPeople.length === 0 && query !== '' ? (
                                                                        <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                                                            Nothing found.
                                                                        </div>
                                                                    ) : (
                                                                        filteredPeople.map((person) => (
                                                                            <Combobox.Option
                                                                                key={person.id}
                                                                                className={({ active }) =>
                                                                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-primaryBlue text-white' : 'text-gray-900'
                                                                                    }`
                                                                                }
                                                                                value={person}
                                                                            >
                                                                                {({ selected, active }) => (
                                                                                    <>
                                                                                        <span
                                                                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                                                                                }`}
                                                                                        >
                                                                                            {person.name}
                                                                                        </span>
                                                                                        {selected ? (
                                                                                            <span
                                                                                                className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                                                                                                    }`}
                                                                                            >
                                                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                            </span>
                                                                                        ) : null}
                                                                                    </>
                                                                                )}
                                                                            </Combobox.Option>
                                                                        ))
                                                                    )}
                                                                </Combobox.Options>
                                                            </Transition>
                                                        </div>
                                                    </Combobox>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] py-2 px-4 select-tag-item'>
                                            <label htmlFor="full-name" className="block text-sm font-normal leading-5 text-primaryDark">
                                                Select tags
                                            </label>
                                            <div>
                                                {/* <MultiSelect
                                                    options={DropdownOptions}
                                                    value={selected}
                                                    onChange={(selectedItems) => handleTags(selectedItems)}
                                                    labelledBy="Select"
                                                /> */}

                                                <PaginateMultiSelect
                                                    selectMostUsed={selectMostUsed}
                                                    setSelectMostUsed={setSelectMostUsed}
                                                    setFilterData={setFilterData}
                                                    fetchData={AdminTagDropdown}
                                                    tag_type={selected}
                                                    // fetchData={AdminNewTagDropdown}
                                                    selctedItems={formData.tags.length > 0 && formData.tags}
                                                    searchAPI={AdminHeaderSearch.bind(null, 'search-tag')}
                                                    placeholder="Search Tags"
                                                    selectedKey="tags"
                                                />
                                            </div>
                                        </div>
                                        <div className='mt-3'>
                                            <h2
                                                className='text-sm font-semibold mb-2'
                                            >Most Used</h2>
                                            <div className='flex flex-wrap gap-x-3 gap-y-2'>
                                                {MostUsedTags.map((item, index) => (

                                                    <span
                                                        onClick={() => handleTags([...selectMostUsed, item])}
                                                        className='text-primaryBlue cursor-pointer text-xs font-normal'
                                                        key={'item-' + index} >{item.name}</span>
                                                ))}


                                            </div>
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>

                        <Disclosure defaultOpen>
                            {({ open }) => (
                                <>
                                    <div className="flex w-full justify-between text-left text-base text-primaryDark font-bold ">
                                        <span>Feature Image</span>
                                        {/* <img
                                            src={arrowUp}
                                            className={`${open ? '' : 'rotate-180 transform'
                                                } h-5 w-5`}
                                        /> */}
                                    </div>
                                    <Disclosure.Panel className="pt-4 pb-2 text-sm text-primaryDark">
                                        {
                                            // (formData.feature_image === "null" || formData.feature_image === null) ?


                                            <div>
                                                {selectedFile || (formData.feature_image !== "null" && formData.feature_image !== null) ?
                                                    (
                                                        <div className='relative'>
                                                            {/* <span className='absolute  right-0 bg-whiteGrey rounded-sm p-2'>remove</span> */}
                                                            {
                                                                selectedFile ?
                                                                    <img
                                                                        src={URL.createObjectURL(selectedFile)}
                                                                        alt="Preview"
                                                                        className='w-full max-h-[200px] object-contain '
                                                                    />
                                                                    :
                                                                    <>
                                                                        <img
                                                                            src={formData.feature_image}
                                                                            alt="Preview"
                                                                            className='w-full max-h-[200px] object-contain '
                                                                        />
                                                                    </>
                                                            }
                                                            <div style={{ background: `linear-gradient(0deg, rgba(22, 30, 46, 0.70) 0%, rgba(22, 30, 46, 0.30) 100%) ` }} className='w-full h-full rounded-xl absolute top-0 flex items-center justify-center bg-opacity-70 opacity-0 transition-opacity duration-300 hover:opacity-100'>
                                                                <div>
                                                                    <label

                                                                        // htmlFor={`fileInput`}
                                                                        onClick={ReplaceImage}
                                                                        className="flex cursor-pointer w-[190px] gap-x-2 px-5 h-11 items-center justify-center rounded-3xl border-[1px] border-whiteGrey hover:bg-opacity-[0.9] text-sm md:text-base font-bold leading-[18px] text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                                    >
                                                                        <span aria-hidden="true" className='text-[13px]'>
                                                                            <ReplaceIconWhite />
                                                                        </span>
                                                                        Replace Image
                                                                    </label>

                                                                </div>
                                                                {/* <input
                                                            type="file"
                                                            id="fileInput"
                                                            accept="image/*"  // Specify the file types allowed (e.g., images)
                                                            style={{ display: 'none' }}
                                                            onChange={handleFileChange}
                                                        /> */}
                                                            </div>
                                                        </div>
                                                    )
                                                    :
                                                    <div
                                                        className=' border-[#b6cfec] p-4 rounded border-dashed border-[1px] flex flex-col justify-center items-center'>
                                                        <label htmlFor="fileInput" className='flex flex-col justify-center items-center gap-4 cursor-pointer'>
                                                            <span className='w-[51.587px] h-[44.69px]'>
                                                                <img className='w-full h-auto' src={upload} alt="uploadfile" />
                                                            </span>
                                                            <span className='text-primaryBlue text-base font-bold underline'>
                                                                Upload or Choose Image
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="fileInput"
                                                            accept="image/*"  // Specify the file types allowed (e.g., images)
                                                            style={{ display: 'none' }}
                                                            onChange={handleFileChange}
                                                        />

                                                        <div
                                                            className='mt-[10px] mb-4 text-base font-semibold leading-[normal] flex justify-center'
                                                        >Or</div>
                                                        <div className='w-full'>
                                                            <button
                                                                type="button"
                                                                onClick={() => setIsOpenMedia(true)}
                                                                className="mx-auto flex w-full h-8 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue px-3 py-1.5 text-sm font-bold leading-[28px] text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 disabled:opacity-50"
                                                            >
                                                                Choose file from media
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            // :
                                            // <>
                                            //     <img
                                            //         src={formData.feature_image}
                                            //         alt="Preview"
                                            //         className='w-full max-h-[200px] object-contain '
                                            //     />
                                            // </>
                                        }
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    </>
                }
            </div>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => closeModal({ date: formData.date, time: formData.time})}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-[411px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg flex justify-between items-center font-medium leading-6 text-gray-900"
                                    >
                                        <div className='flex w-[90%] items-center justify-between'>
                                            Publish
                                            <button
                                                onClick={() => handlePublishTime('now')}
                                                className="px-4 py-1 bg-transparent hover:bg-primaryBlue hover:bg-opacity-10 border-[1px] border-primaryBlue text-primaryBlue rounded-md transition duration-300"

                                            >Now</button>
                                        </div>
                                        <span
                                            onClick={() => closeModal({ date: formData.date, time: formData.time})}
                                            className='cursor-pointer'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                <path d="M1 13L13 1M1 1L13 13" stroke="#64748B" strokeOpacity="0.5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </span>
                                    </Dialog.Title>
                                    <div >
                                        <div className={`h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4 mt-6 ${timeInputError ? "" : "mb-6"}`}>
                                            <label htmlFor="time" className="block text-sm font-normal leading-5 text-primaryDark">
                                                Time
                                            </label>
                                            <div className="">
                                                <input
                                                    id="time"
                                                    name="time"
                                                    type="time"
                                                    autoComplete="off"
                                                    required
                                                    value={selectedTime}
                                                    onChange={handleChange}
                                                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-2 mt-[2px] mb-[6px] text-xs text-red-500">{timeInputError}</div>
                                        <div className={`${dateInputError ? "" : "mb-6"} h-[85px] rounded-lg bg-[#D2D6DC] md:bg-opacity-[0.4] pt-4 px-4`}>
                                            <label htmlFor="time" className="block text-sm font-normal leading-5 text-primaryDark">
                                                Date
                                            </label>
                                            <div className="">
                                                <input
                                                    id="date"
                                                    name="date"
                                                    type="date"
                                                    autoComplete="off"
                                                    required
                                                    min="2017-01-01"
                                                    value={selectedDate}
                                                    onChange={handleChange}
                                                    className="block w-full bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                                                />
                                            </div>
                                        </div>
                                        <div className="ml-2 mt-[2px] mb-[6px] text-xs text-red-500">{dateInputError}</div>
                                        <div className="flex justify-center gap-4 items-center">
                                            <button
                                                type="button"
                                                className="w-[146px] h-[45px] py-2 bg-transparent border-[1px] border-primaryBlue text-primaryBlue rounded-[61px] transition duration-300"
                                                onClick={() => closeModal({ date: formData.date, time: formData.time})}
                                            >
                                                Cancel
                                            </button>

                                            <button
                                                type="button"
                                                // disabled={loading}
                                                onClick={() => handlePublishTime('date')}
                                                className="w-[146px] h-[45px] py-2 text-white bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue rounded-[61px] transition duration-300 disabled:opacity-50"
                                            >
                                                Set Time
                                            </button>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
            <MediaGallaryModal
                mediatype={'image'}
                setIsOpenMedia={setIsOpenMedia}
                isOpenMedia={isOpenMedia}
                selectedMedia={selectedMedia}
            />
        </>
    )
}

export default TabSection1