import React from 'react'
import logo from "../../assets/footer-logo.svg"
import { Link } from 'react-router-dom'
function Footer() {
    const quickLinks = [
        {
            name: 'Home',
            href: '/'
        },
        // {
        //     name: 'Calendar',
        //     href: '#'
        // },
        {
            name: 'About Us',
            href: '/about-us'
        },
        {
            name: 'Contact Us',
            href: '/contact-us'
        },
        {
            name: 'Donate',
            href: '/donations'
        },
        {
            name: 'Privacy policy',
            href: '/privacy-policy'
        },
        // {
        //     name: 'Sitemap',
        //     href: '#'
        // },
        // {
        //     name: 'Subscribe',
        //     href: '#'
        // },

    ]
    return (
        <footer id='main-footer' className='bg-primaryDark px-2 xs:px-5 py-8 lg:py-[60px]'>
            <div className="2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto">
                <div className='flex flex-col md:flex-row gap-y-6 justify-between text-whiteGrey'>
                    <div className='space-y-6'>
                        <div className='text-xs leading-7 sm:text-xl font-normal'>
                            A project of
                        </div>
                        <div>
                            <Link
                                to="/"
                            >
                                <span className="sr-only">Inside Cassidus</span>
                                <img className="h-[35px] lg:h-[63px]" src={logo} alt="Inside Cassidus" />
                            </Link>
                        </div>
                    </div>
                    <div>
                        <div className='text-base font-bold text-primaryBlue'>
                            Quick links
                        </div>
                        <ul className='flex mt-3 flex-col gap-y-4 lg:gap-x-[55px] flex-wrap max-h-[200px]'>

                            {
                                quickLinks.map((obj) => (
                                    <li key={obj.name}>
                                        <Link to={obj?.href} className="text-base font-normal leading-7 hover:text-primaryBlue text-whiteGrey flex items-center">
                                            {obj.name}
                                        </Link>
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                    <div className='flex flex-col justify-between'>
                        <div>
                            <div className='text-base font-bold text-primaryBlue'>
                                Address
                            </div>
                            <div className='mt-3 w-44 text-base font-normal leading-7'>
                                437 Sterling StSuite 100Brooklyn NY11225
                            </div>
                        </div>
                        <div>
                            <div className='text-base mt-6 font-bold text-primaryBlue'>
                                Email
                            </div>
                            <div className='mt-3'>
                                <Link
                                    to="mailto:Info@insidechassidus.org"
                                    className="w-44 text-base font-normal leading-7"
                                >
                                    Info@insidechassidus.org
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='text-base font-bold text-primaryBlue'>
                            Follow us
                        </div>
                        <div className='flex items-center gap-x-[27px] mt-3'>
                            {/* <Link>
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="28" viewBox="0 0 27 28" fill="none">
                                    <g clipPath="url(#clip0_148_2039)">
                                        <path d="M27 13.6398C27 21.1723 21.0659 27.2787 13.7443 27.2787C11.4209 27.2787 9.2383 26.6624 7.33834 25.5832L0 28L2.39212 20.6802C1.18593 18.6259 0.491277 16.2136 0.491277 13.6389C0.492158 6.10637 6.42446 0 13.7461 0C21.0668 0.00182607 27 6.10728 27 13.6398ZM13.7434 2.17485C7.59895 2.17485 2.60078 7.31888 2.60078 13.6425C2.60078 16.1516 3.38964 18.4743 4.72348 20.3643L3.33241 24.6218L7.61304 23.2111C9.37389 24.409 11.4807 25.1066 13.7434 25.1066C19.8879 25.1066 24.8879 19.9635 24.8879 13.6398C24.8896 7.31888 19.8888 2.17485 13.7434 2.17485ZM20.4382 16.7816C20.3554 16.6437 20.1388 16.5597 19.8148 16.3917C19.4917 16.2237 17.892 15.4157 17.5953 15.3061C17.296 15.1938 17.0794 15.1372 16.8628 15.4723C16.6488 15.8073 16.0255 16.5597 15.8344 16.7843C15.6452 17.008 15.4559 17.0363 15.131 16.871C14.8061 16.7012 13.7584 16.3506 12.517 15.2121C11.5512 14.3246 10.8988 13.2317 10.7104 12.8966C10.5193 12.5624 10.691 12.3816 10.8521 12.2155C10.9974 12.0648 11.177 11.8247 11.3399 11.6284C11.5028 11.4339 11.5565 11.2961 11.6648 11.0724C11.7713 10.8487 11.7176 10.6542 11.6375 10.4853C11.5565 10.3173 10.9076 8.67382 10.6355 8.00457C10.3652 7.33622 10.095 7.4467 9.90478 7.4467C9.71549 7.4467 9.49891 7.41931 9.28232 7.41931C9.06574 7.41931 8.71357 7.50057 8.41687 7.83565C8.12016 8.17074 7.28024 8.97877 7.28024 10.6241C7.28024 12.2712 8.44328 13.8589 8.60616 14.0817C8.76992 14.3036 10.8548 17.7905 14.1572 19.129C17.4615 20.4666 17.4615 20.0201 18.0566 19.9635C18.6536 19.9069 19.9795 19.1554 20.2489 18.3757C20.5201 17.5923 20.5201 16.9212 20.4382 16.7816Z" fill="#D2D6DC" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_148_2039">
                                            <rect width="27" height="28" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </Link> */}
                            <Link
                                to={'https://www.facebook.com/insidechassidus.org/'}
                                target="_blank"
                            >

                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                    <path d="M26.0343 37.1429V25.152H30.0595L30.6618 20.4777H26.0332V17.4949C26.0332 16.144 26.4103 15.2206 28.3452 15.2206H30.8206V11.04C30.392 10.9829 28.9235 10.8572 27.2149 10.8572C23.6458 10.8572 21.2035 13.0343 21.2035 17.0343V20.4812H17.1692V25.1555H21.2035V37.1429H26.0298H26.0343Z" fill="#D2D6DC" />
                                </svg>
                            </Link>
                            {/* <Link>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                                    <path d="M26.2339 22.1624L34.9764 12H32.9047L25.3137 20.8238L19.2507 12H12.2578L21.4262 25.3432L12.2578 36H14.3296L22.3459 26.6817L28.7489 36H35.7418L26.2334 22.1624H26.2339ZM23.3963 25.4608L22.4674 24.1321L15.0761 13.5596H18.2583L24.2231 22.0919L25.1521 23.4206L32.9057 34.5113H29.7235L23.3963 25.4613V25.4608Z" fill="#D2D6DC" />
                                </svg>
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer