import React, { useState, useRef, useEffect } from 'react';
import {
    PauseIcon,
    PlayIcon,
    RewindTen,
    ForwordTen,
    VolumeIcon,
    VolumeMuteIcon,
    DownloadIcon,
} from '../assets/icon-file';

const CustomVideoPlayer = ({ videoUrl, onPlay }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [speed, setSpeed] = useState(1.0);
    const [volume, setVolume] = useState(1.0);
    const [isMuted, setIsMuted] = useState(false);
    const [dragging, setDragging] = useState(false);
    useEffect(() => {
        const videoElement = videoRef.current;

        const handleLoadedMetadata = () => {
            setDuration(videoElement.duration);
        };

        // const handleTimeUpdate = () => {
        //   setCurrentTime(videoElement.currentTime);
        // };
        const handleTimeUpdate = () => {
            if (!dragging) {
                setCurrentTime(videoElement.currentTime);
            }
        };
        const handleEnded = () => {
            // Video completed, pause and reset to start
            videoElement.pause();
            videoElement.currentTime = 0;
            setIsPlaying(false);
        };

        videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
        videoElement.addEventListener('timeupdate', handleTimeUpdate);
        videoElement.addEventListener('ended', handleEnded);

        return () => {
            videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
            videoElement.removeEventListener('timeupdate', handleTimeUpdate);
            videoElement.removeEventListener('ended', handleEnded);
        };
    }, [dragging]);

    const playPauseToggle = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
            if (onPlay) {
                onPlay(); // Call the onPlay function if provided
            }
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handlePlaybackSpeedChange = (newSpeed) => {
        setSpeed(newSpeed);
        videoRef.current.playbackRate = newSpeed;
    };

    const handleVolumeChange = (newVolume) => {
        setVolume(newVolume);
        videoRef.current.volume = newVolume;
        setIsMuted(false);
    };

    const toggleMute = () => {
        const newIsMuted = !isMuted;
        setIsMuted(newIsMuted);
        videoRef.current.muted = newIsMuted;
    };

    //   const handleTimeChange = (newTime) => {
    //     videoRef.current.currentTime = newTime;
    //   };

    const handleTimeChange = (newTime) => {
        videoRef.current.currentTime = newTime;
        setCurrentTime(newTime);
    };

    const handleDragStart = () => {
        setDragging(true);
    };

    const handleDragEnd = (e) => {
        setDragging(false);
        handleTimeChange(parseFloat(e.target.value));
    };
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const downloadVideo = (videoUrl) => {
        // Create a link element
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = videoUrl;
        //a.download = 'downloaded_audio.mp3'; // You can specify the filename here
  
        // Append the link to the body
        document.body.appendChild(a);
  
        // Trigger the download by simulating a click on the link
        a.click();
  
        // Clean up by removing the link and revoking the blob URL
        window.URL.revokeObjectURL(videoUrl);
        document.body.removeChild(a);
        // // Use fetch to get the audio file
        // fetch(videoUrl)
        //   .then(response => {
        //     // Ensure the request was successful
        //     if (response.ok) return response.blob();
        //     throw new Error('Network response was not ok.');
        //   })
        //   .then(blob => {
        //     // Create a blob URL from the blob
        //     const url = window.URL.createObjectURL(blob);
      
        //     // Create a link element
        //     const a = document.createElement('a');
        //     a.style.display = 'none';
        //     a.href = url;
        //     a.download = 'downloaded_audio.mp3'; // You can specify the filename here
      
        //     // Append the link to the body
        //     document.body.appendChild(a);
      
        //     // Trigger the download by simulating a click on the link
        //     a.click();
      
        //     // Clean up by removing the link and revoking the blob URL
        //     window.URL.revokeObjectURL(url);
        //     document.body.removeChild(a);
        //   })
        //   .catch(error => {
        //     console.error('There was a problem with the fetch operation:', error);
        //   });
      };
    return (
        <>
            <video className='w-full rounded-t-xl' ref={videoRef} src={videoUrl} />

            <div className='flex items-center bg-primaryDark text-white p-4 rounded-b-xl gap-7 mt-[-1px] flex-col sm:flex-row'>
                <button className='text-2xl' onClick={playPauseToggle}>
                    {isPlaying ? <PauseIcon /> : <PlayIcon />}
                </button>

                <div className='flex flex-col flex-1 relative bottom-1 w-full'>
                    <input
                        type="range"
                        className='w-full cursor-pointer h-1'
                        min="0"
                        max={duration}
                        step="1"
                        onChange={(e) => setCurrentTime(parseFloat(e.target.value))}
                        onMouseUp={(e) => handleDragEnd(e)}
                        onTouchEnd={(e) => handleDragEnd(e)}
                        onMouseDown={handleDragStart}
                        onTouchStart={handleDragStart}
                        value={currentTime}
                    />
                    <div className='flex justify-between absolute w-full top-3 text-xs'>
                        <span>{formatTime(currentTime)}</span> <span>{formatTime(duration)}</span>
                    </div>
                </div>

                <div className='flex items-center gap-5'>
                    <div>
                        <select
                            className='bg-primaryDark'
                            value={speed}
                            onChange={(e) => handlePlaybackSpeedChange(parseFloat(e.target.value))}
                        >
                            <option value={0.5}>0.5x</option>
                            <option value={1.0}>1.0x</option>
                            <option value={1.5}>1.5x</option>
                            <option value={2.0}>2.0x</option>
                        </select>
                    </div>
                    <div className='flex items-center gap-5'>
                        <button onClick={() => handleTimeChange(currentTime - 10)}>
                            <RewindTen />
                        </button>
                        <button onClick={() => handleTimeChange(currentTime + 10)}>
                            <ForwordTen />
                        </button>
                    </div>

                    <div className='flex gap-1 items-center'>
                        <span onClick={toggleMute}>{isMuted ? <VolumeMuteIcon /> : <VolumeIcon />}</span>
                        <input
                            type='range'
                            className='h-1 w-16'
                            min='0'
                            max='1'
                            step='0.1'
                            value={volume}
                            onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
                        />
                    </div>
                    <div className='flex items-center gap-5'>
                        <button onClick={() => downloadVideo(videoUrl)}>
                        <DownloadIcon />
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CustomVideoPlayer;
