import React, { useState } from 'react'
import Header from './Header'
import { Disclosure } from '@headlessui/react'
import { AdvanceUserSearch, UserSearch } from '../../service/service';
import { ColorRing } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/24/outline';

function SearchPage() {
  const navigate = useNavigate();
  const [inputText, setInputText] = useState('');
  const [loading, setloading] = useState(false);
  const [classes, setClassess] = useState([]);
  const [series, setSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [flag, setFlag] = useState(false)
  const [inputValue, setInputValue] = useState('');
  const [chips, setChips] = useState([]);
  const [formData, setFormData] = useState({
    category: 1,
    series: 1,
    classes: 1,
  });
  const [isOpen, setIsOpen] = useState(false)
  const checkOpen = (open) => {

    setIsOpen(open)
  }
  const HandleSearch = () => {

    setloading(true)
    setFlag(true)
    if (isOpen) {
      const data = { ...formData, tag: chips }
      AdvanceUserSearch(data)
        .then((result) => {
          setloading(false)
          // console.log(result.data)
          setClassess(result.data?.classes?.data ?? []);
          setSeries(result.data?.series?.data ?? []);
          setCategories(result.data?.category.data ?? []);
        });
    }
    else {
      const data = { search: inputText };

      UserSearch(data)
        .then((result) => {
          setloading(false)
          // console.log(result.data)
          setClassess(result.data?.classes?.data ?? []);
          setSeries(result.data?.series?.data ?? []);
        });
    }
    // console.log(formData)
  }
  const OpenClass = (obj) => {

    const data = obj;
    navigate(`/${data.custom_permalink ?? data.slug}`);
  }
  const OpenSeries = (obj) => {

    const data = obj;
    navigate(`/series/${data.perma_link ?? data.slug}`);

  }
  const OpenCategory = (obj) => {

    const data = obj;
    navigate(`/${data?.slug}`);

  }
  const viewAll = (param) => {
    // console.log(param)
    const data = {
      type: param,
      query: isOpen ? chips.join(',') : inputText,
      classes,
      series,
      categories,
    }

    navigate('/view-all', { state: { data } });
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      // Function to call on 'Enter' key press
      HandleSearch()
    }
  };



  const handleInputChange = (event) => {
    const { name, type, checked, value } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    if (type === 'checkbox') {

      setFormData((prevData) => ({
        ...prevData,
        [name]: newValue ? 1 : 0,
      }));
    }
    else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: newValue,
      }));
    }
  };


  const handleInputChange2 = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      const newChip = inputValue.trim();
      setChips([...chips, newChip]);
      setInputValue('');
    }
    else if (event.key === 'Backspace' && inputValue === '' && chips.length > 0) {
      const updatedChips = chips.slice(0, -1); // Remove the most recent chip
      setChips(updatedChips);
    }
  };

  const handleChipDelete = (chipToDelete) => {
    const updatedChips = chips.filter(chip => chip !== chipToDelete);
    setChips(updatedChips);
  };
  const handleClearAll = () => {
    setChips([]);
  };
  return (
    <div>
      <Header />
      <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
        <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
          <div className='flex flex-col sm:flex-row justify-between items-center gap-2 md:gap-6'>
          {
            !isOpen &&
              <div className='relative self-baseline flex w-full flex-1 items-center justify-between gap-2 px-8 py-[14px] h-12 bg-[#D2D6DC] bg-opacity-[0.4]   rounded-3xl'>
              <span>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M8.80039 2.20002C7.04996 2.20002 5.37123 2.89537 4.13349 4.13311C2.89575 5.37085 2.20039 7.04959 2.20039 8.80002C2.20039 10.5504 2.89575 12.2292 4.13349 13.4669C5.37123 14.7047 7.04996 15.4 8.80039 15.4C10.5508 15.4 12.2296 14.7047 13.4673 13.4669C14.705 12.2292 15.4004 10.5504 15.4004 8.80002C15.4004 7.04959 14.705 5.37085 13.4673 4.13311C12.2296 2.89537 10.5508 2.20002 8.80039 2.20002ZM0.400391 8.80002C0.400504 7.45682 0.722725 6.13324 1.34003 4.94029C1.95733 3.74735 2.8517 2.71983 3.94813 1.94393C5.04457 1.16804 6.31108 0.666387 7.64143 0.481064C8.97178 0.295741 10.3272 0.432149 11.5939 0.878846C12.8607 1.32554 14.0018 2.0695 14.9216 3.04832C15.8415 4.02713 16.5132 5.21226 16.8804 6.50429C17.2476 7.79631 17.2996 9.15756 17.0321 10.4738C16.7645 11.7901 16.1852 13.0231 15.3428 14.0692L19.3364 18.064C19.4248 18.1464 19.4957 18.2458 19.5449 18.3562C19.5941 18.4666 19.6206 18.5857 19.6227 18.7066C19.6248 18.8274 19.6026 18.9475 19.5573 19.0595C19.5121 19.1716 19.4447 19.2734 19.3592 19.3589C19.2738 19.4443 19.172 19.5117 19.0599 19.557C18.9478 19.6022 18.8278 19.6245 18.707 19.6223C18.5861 19.6202 18.4669 19.5937 18.3565 19.5446C18.2461 19.4954 18.1468 19.4244 18.0644 19.336L14.0696 15.3424C12.8354 16.3365 11.3453 16.9612 9.7712 17.1443C8.19712 17.3274 6.60334 17.0616 5.17393 16.3774C3.74452 15.6933 2.53779 14.6188 1.69312 13.2779C0.848446 11.9371 0.400283 10.3847 0.400391 8.80002Z" fill="#515F7B" />
                </svg>
              </span>
              <input
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={handleKeyPress}
                className='w-full bg-transparent outline-none' placeholder='Search...' type="text" />
            </div>
          }
          {
            isOpen &&
            <>
            
            <div className='flex w-full flex-1 justify-between items-center pb-5 gap-6 flex-col md:flex-row'>
              <div className='flex w-full flex-1 items-center gap-2 md:gap-5 lg:gap-14'>
                <div className='flex gap-2'>
                  <input
                    checked={formData.category === 1}
                    onChange={handleInputChange}
                    className='h-7 w-7' name={`category`} type="checkbox" id={`item-category`} />
                  <label className='text-lg font-semibold' htmlFor={`item-category`}>Categories</label>
                </div>
                <div className='flex gap-2'>
                  <input
                    checked={formData.series === 1}
                    onChange={handleInputChange}
                    className='h-7 w-7' name={`series`} type="checkbox" id={`item-series`} />
                  <label className='text-lg font-semibold' htmlFor={`item-series`}>Series</label>
                </div>
                <div className='flex gap-2'>
                  <input
                    checked={formData.classes === 1}
                    onChange={handleInputChange}
                    className='h-7 w-7' name={`classes`} type="checkbox" id={`item-classes`} />
                  <label className='text-lg font-semibold' htmlFor={`item-classes`}>Classes</label>
                </div>

              </div>
              <div className='min-h-[85px] w-full flex-1 rounded-lg bg-[#D2D6DC] bg-opacity-[0.4] pt-4 px-4'>
                <label htmlFor="tag-name" className="block text-sm font-normal leading-5 text-primaryDark">
                  Search by Tags/Slug
                </label>
                <div className='flex items-center justify-between'>
                <div className="flex flex-wrap">
                  {/* <div> */}
                  {chips.map((chip, index) => (
                    <div key={index} className="chip">
                      {chip}
                      <span
                        className="close-icon"
                        onClick={() => handleChipDelete(chip)}
                      >
                        &times;
                      </span>
                    </div>
                  ))}
                  {/* </div> */}
                  <input
                    id="tag-name"
                    name="tag"
                    type="text"
                    placeholder="Type and press Enter"
                    value={inputValue}
                    onChange={handleInputChange2}
                    onKeyDown={handleInputKeyDown}
                    className="block bg-transparent outline-none border-0 py-1.5 text-primaryDark focus:border-transparent sm:text-lg sm:leading-normal font-semibold"
                  />
                  
                </div>
                <div>
                {chips.length > 0 && (
                    <button onClick={handleClearAll}><XMarkIcon class="h-6 w-6 text-gray-500" /></button>
                  )}
                </div>
                </div>
              </div>
              {/* <div className='w-[100px] md:w-[137px]'>

              </div> */}
            </div>
          </>
          }
          <div className='flex w-full sm:w-[unset] flex-col'>

            <button
              type="button"
              onClick={HandleSearch}
              className="flex gap-x-2 w-full sm:w-[100px] md:w-[137px] h-11 items-center justify-center rounded-3xl bg-primaryBlue hover:bg-opacity-[0.9] focus:bg-primaryBlue  text-sm md:text-base font-normal leading-7 text-whiteGrey shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              Search
              <span aria-hidden="true" className='text-[13px]'>
                <svg className='relative right-[-1px]' width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.8086 12.8633C14.0547 13.1367 14.0547 13.5469 13.7812 13.793L13.0156 14.5586C12.7695 14.832 12.3594 14.832 12.0859 14.5586L9.37891 11.8516C9.24219 11.7148 9.1875 11.5508 9.1875 11.3867V10.9219C8.20312 11.6875 7 12.125 5.6875 12.125C2.54297 12.125 0 9.58203 0 6.4375C0 3.32031 2.54297 0.75 5.6875 0.75C8.80469 0.75 11.375 3.32031 11.375 6.4375C11.375 7.77734 10.9102 8.98047 10.1719 9.9375H10.6094C10.7734 9.9375 10.9375 10.0195 11.0742 10.1289L13.8086 12.8633ZM5.6875 9.9375C7.60156 9.9375 9.1875 8.37891 9.1875 6.4375C9.1875 4.52344 7.60156 2.9375 5.6875 2.9375C3.74609 2.9375 2.1875 4.52344 2.1875 6.4375C2.1875 8.37891 3.74609 9.9375 5.6875 9.9375Z" fill="white" />
                </svg>
              </span>
            </button>
            <Disclosure>
            {({ open }) => (
              <>
                <div className='flex items-center justify-end py-2'>
                  <Disclosure.Button
                    onClick={() => checkOpen(!open)}
                    className='text-sm md:text-base leading-[18px] font-semibold text-primaryBlue w-[110px] md:w-[137px] text-center cursor-pointer'>
                    Advance Search
                  </Disclosure.Button>
                </div>
               
              </>
            )}
          </Disclosure>
          </div>
          </div>
       
          <div>
            <hr />
          </div>
          <div className='py-8'>
            {
              loading ?
                <div className='flex justify-center'>
                  <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="blocks-loading"
                    wrapperStyle={{}}
                    wrapperClass="blocks-wrapper"
                    colors={['#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC']}
                  />
                </div>

                :
                <>
                  {
                    classes.length === 0 && series.length === 0 && categories.length === 0 ?
                      <div className='flex justify-center h-[50vh]'>
                        {flag ? 'No data found' : "Search for classes, series or categories"}
                      </div>
                      :
                      <>
                        {
                          classes.length > 0 &&
                          <>
                            <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                              Classes
                            </div>
                            <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                              {
                                classes?.slice(0, 3)?.map((obj) => (
                                  <div key={obj.id}
                                    onClick={() => OpenClass(obj)}
                                    className='cursor-pointer rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px] flex justify-between'>
                                    <div className='pl-3 sm:pl-6 flex items-center justify-center gap-x-2 sm:gap-x-4'>
                                      <div className='h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] px-6 bg-whiteGrey flex items-center justify-center rounded-lg'>
                                        <span>
                                          <svg className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                            <g clipPath="url(#clip0_326_587)">
                                              <path d="M25.3333 20V5.33334C25.3324 5.13306 25.2864 4.93557 25.1987 4.75552C25.111 4.57548 24.9838 4.4175 24.8267 4.29334C24.6718 4.16803 24.4908 4.07903 24.2969 4.03289C24.1031 3.98674 23.9014 3.98461 23.7067 4.02667L11.7067 6.69334C11.4104 6.76014 11.1459 6.92607 10.9568 7.16368C10.7676 7.40128 10.6653 7.69633 10.6667 8V19.12C9.75645 18.6903 8.73143 18.5673 7.74539 18.7695C6.75934 18.9717 5.86545 19.4882 5.19779 20.2414C4.53014 20.9947 4.12473 21.9441 4.04238 22.9473C3.96004 23.9505 4.20519 24.9533 4.74107 25.8054C5.27695 26.6574 6.07467 27.3127 7.01454 27.673C7.95441 28.0333 8.98576 28.0791 9.95388 27.8036C10.922 27.5281 11.7747 26.9461 12.384 26.1449C12.9933 25.3437 13.3264 24.3665 13.3333 23.36C13.3471 23.1291 13.3471 22.8976 13.3333 22.6667V9.06667L22.6667 7V16.4533C21.7559 16.0234 20.7303 15.9005 19.7438 16.1031C18.7572 16.3058 17.8631 16.823 17.1956 17.5772C16.528 18.3313 16.1232 19.2817 16.0419 20.2855C15.9606 21.2894 16.2071 22.2925 16.7445 23.1443C17.2819 23.996 18.0812 24.6505 19.0222 25.0093C19.9632 25.3681 20.9953 25.4119 21.9634 25.1342C22.9314 24.8564 23.7833 24.2721 24.3909 23.4689C24.9986 22.6657 25.3293 21.6871 25.3333 20.68C25.346 20.4535 25.346 20.2265 25.3333 20Z" fill="#161E2E" />
                                            </g>
                                            <defs>
                                              <clipPath id="clip0_326_587">
                                                <rect width="32" height="32" fill="white" />
                                              </clipPath>
                                            </defs>
                                          </svg>
                                        </span>
                                      </div>
                                      <div className='text-primaryDark'>
                                        <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{obj.title}</div>
                                        <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{obj.description}</div>
                                      </div>
                                    </div>
                                    <div className='flex items-center justify-center pr-4 sm:pr-9 gap-x-4 sm:gap-x-8'>

                                    </div>
                                  </div>
                                ))
                              }
                              {
                                classes.length > 3 &&
                                <div onClick={() => viewAll('class')} className='text-sm font-bold cursor-pointer pl-4 pt-4 text-primaryBlue'>View all</div>
                              }
                            </div>
                          </>
                        }
                        {
                          series.length > 0 &&

                          <>
                            <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                              Series
                            </div>
                            <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                              {
                                series?.slice(0, 3)?.map((obj) => (
                                  <div key={obj.id} onClick={() => OpenSeries(obj)} className='cursor-pointer rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px] flex justify-between'>
                                    <div className='pl-3 sm:pl-6 flex items-center justify-center gap-x-2 sm:gap-x-4'>
                                      <div className='h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] px-6 bg-whiteGrey flex items-center justify-center rounded-lg'>
                                        <span>

                                          <svg className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                            <path d="M5.89655 15H36.3103C37.0786 15 37.8153 15.3052 38.3585 15.8484C38.9017 16.3916 39.2069 17.1283 39.2069 17.8966V35.2759C39.2069 36.0441 38.9017 36.7808 38.3585 37.324C37.8153 37.8672 37.0786 38.1724 36.3103 38.1724H5.89655C5.12834 38.1724 4.39159 37.8672 3.84838 37.324C3.30517 36.7808 3 36.0441 3 35.2759V17.8966C3 17.1283 3.30517 16.3916 3.84838 15.8484C4.39159 15.3052 5.12834 15 5.89655 15Z" fill="#161E2E" />
                                            <path d="M25.4494 25.4119L17.9375 21.0719C17.7228 20.9448 17.4782 20.8769 17.2287 20.875C16.8366 20.875 16.4604 21.0308 16.1831 21.3081C15.9058 21.5854 15.75 21.9616 15.75 22.3537V31.6681C15.75 31.9284 15.8198 32.1839 15.952 32.408C16.0843 32.6322 16.2741 32.8169 16.5019 32.9428C16.7297 33.0687 16.987 33.1314 17.2472 33.1242C17.5073 33.117 17.7608 33.0402 17.9813 32.9019L25.5019 28.1506C25.7357 28.0043 25.9277 27.7998 26.059 27.5573C26.1903 27.3147 26.2566 27.0422 26.2513 26.7664C26.246 26.4906 26.1694 26.2208 26.0288 25.9835C25.8883 25.7461 25.6887 25.5492 25.4494 25.4119Z" fill="#D2D6DC" />
                                            <path d="M10.138 3.62067H31.8621C32.2462 3.62067 32.6146 3.77325 32.8862 4.04486C33.1578 4.31646 33.3104 4.68484 33.3104 5.06894C33.3104 5.45305 33.1578 5.82142 32.8862 6.09303C32.6146 6.36463 32.2462 6.51722 31.8621 6.51722H10.138C9.75387 6.51722 9.38549 6.36463 9.11389 6.09303C8.84228 5.82142 8.6897 5.45305 8.6897 5.06894C8.6897 4.68484 8.84228 4.31646 9.11389 4.04486C9.38549 3.77325 9.75387 3.62067 10.138 3.62067Z" fill="#161E2E" />
                                            <path d="M7.24149 9.41382H34.7587C35.1428 9.41382 35.5112 9.5664 35.7828 9.83801C36.0544 10.1096 36.207 10.478 36.207 10.8621C36.207 11.2462 36.0544 11.6146 35.7828 11.8862C35.5112 12.1578 35.1428 12.3104 34.7587 12.3104H7.24149C6.85738 12.3104 6.48901 12.1578 6.2174 11.8862C5.9458 11.6146 5.79321 11.2462 5.79321 10.8621C5.79321 10.478 5.9458 10.1096 6.2174 9.83801C6.48901 9.5664 6.85738 9.41382 7.24149 9.41382Z" fill="#161E2E" />
                                          </svg>
                                        </span>
                                      </div>
                                      <div className='text-primaryDark'>
                                        <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{obj.title}</div>
                                        <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{obj.description != null ? obj.description : ''}</div>
                                      </div>
                                    </div>
                                    <div className='flex items-center justify-center pr-4 sm:pr-9 gap-x-4 sm:gap-x-8'>

                                    </div>
                                  </div>
                                ))
                              }
                              {
                                 series.length > 3 &&
                              <div onClick={() => viewAll('series')} className='text-sm font-bold cursor-pointer pl-4 pt-4 text-primaryBlue'>View all</div>
                              }
                            </div>
                          </>
                        }
                        {
                          categories.length > 0 &&

                          <>
                            <div className='text-[22px] leading-[normal] md:text-2xl font-extrabold'>
                              Categories
                            </div>
                            <div className='py-4 sm:py-8 space-y-3 sm:space-y-4'>
                              {
                                categories?.slice(0, 3)?.map((obj) => (
                                  <div key={obj.id} onClick={() => OpenCategory(obj)} className='cursor-pointer rounded-xl bg-[#E1E9F2] h-[68px] sm:h-[95px] flex justify-between'>
                                    <div className='pl-3 sm:pl-6 flex items-center justify-center gap-x-2 sm:gap-x-4'>
                                      <div className='h-[40px] w-[40px] xs:h-[44px] xs:w-[58px] sm:h-[63px] sm:w-[80px] px-6 bg-whiteGrey flex items-center justify-center rounded-lg'>
                                        <span>
                                          <svg className='w-[25px] h-[25px] xs:w-[27px] xs:h-[27px] sm:w-[32px] sm:h-[32px]' xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                            <path d="M5.89655 15H36.3103C37.0786 15 37.8153 15.3052 38.3585 15.8484C38.9017 16.3916 39.2069 17.1283 39.2069 17.8965V35.2758C39.2069 36.0441 38.9017 36.7808 38.3585 37.324C37.8153 37.8672 37.0786 38.1724 36.3103 38.1724H5.89655C5.12834 38.1724 4.39159 37.8672 3.84838 37.324C3.30517 36.7808 3 36.0441 3 35.2758V17.8965C3 17.1283 3.30517 16.3916 3.84838 15.8484C4.39159 15.3052 5.12834 15 5.89655 15Z" fill="#161E2E" />
                                            <path d="M26.9943 29.2576V21.0024C26.9938 20.8896 26.9662 20.7785 26.9138 20.6771C26.8613 20.5758 26.7853 20.4869 26.6913 20.417C26.5986 20.3465 26.4903 20.2964 26.3744 20.2704C26.2585 20.2444 26.1378 20.2432 26.0213 20.2669L18.8437 21.7679C18.6665 21.8055 18.5082 21.8989 18.3951 22.0326C18.282 22.1663 18.2208 22.3324 18.2216 22.5033V28.7623C17.6771 28.5205 17.064 28.4512 16.4743 28.565C15.8845 28.6788 15.3498 28.9695 14.9504 29.3935C14.5511 29.8175 14.3086 30.3519 14.2593 30.9165C14.2101 31.4812 14.3567 32.0457 14.6772 32.5252C14.9978 33.0048 15.4749 33.3737 16.0371 33.5765C16.5993 33.7793 17.2162 33.805 17.7952 33.65C18.3743 33.4949 18.8843 33.1673 19.2488 32.7163C19.6132 32.2654 19.8125 31.7154 19.8166 31.1488C19.8249 31.0189 19.8249 30.8886 19.8166 30.7586V23.1037L25.3993 21.9405V27.2614C24.8545 27.0194 24.241 26.9502 23.651 27.0642C23.0609 27.1783 22.526 27.4694 22.1268 27.8939C21.7275 28.3184 21.4854 28.8533 21.4367 29.4183C21.3881 29.9834 21.5356 30.548 21.857 31.0274C22.1784 31.5068 22.6565 31.8752 23.2194 32.0772C23.7823 32.2791 24.3996 32.3038 24.9786 32.1474C25.5577 31.9911 26.0672 31.6622 26.4306 31.2101C26.7941 30.7581 26.9919 30.2072 26.9943 29.6404C27.0019 29.5129 27.0019 29.3851 26.9943 29.2576Z" fill="#D4DDE9" />
                                            <path d="M10.138 3.62068H31.8621C32.2462 3.62068 32.6146 3.77327 32.8862 4.04487C33.1578 4.31648 33.3104 4.68485 33.3104 5.06896C33.3104 5.45306 33.1578 5.82144 32.8862 6.09304C32.6146 6.36465 32.2462 6.51723 31.8621 6.51723H10.138C9.75387 6.51723 9.38549 6.36465 9.11389 6.09304C8.84228 5.82144 8.6897 5.45306 8.6897 5.06896C8.6897 4.68485 8.84228 4.31648 9.11389 4.04487C9.38549 3.77327 9.75387 3.62068 10.138 3.62068Z" fill="#161E2E" />
                                            <path d="M7.24149 9.4138H34.7587C35.1428 9.4138 35.5112 9.56639 35.7828 9.83799C36.0544 10.1096 36.207 10.478 36.207 10.8621C36.207 11.2462 36.0544 11.6146 35.7828 11.8862C35.5112 12.1578 35.1428 12.3104 34.7587 12.3104H7.24149C6.85738 12.3104 6.48901 12.1578 6.2174 11.8862C5.9458 11.6146 5.79321 11.2462 5.79321 10.8621C5.79321 10.478 5.9458 10.1096 6.2174 9.83799C6.48901 9.56639 6.85738 9.4138 7.24149 9.4138Z" fill="#161E2E" />
                                          </svg>
                                        </span>
                                      </div>
                                      <div className='text-primaryDark'>
                                        <div className='text-base xs:text-lg sm:text-2xl font-extrabold leading-[normal] line-clamp-1'>{obj.title ?? obj.name}</div>
                                        <div className='text-xs xs:text-sm sm:text-base font-semibold leading-[normal] line-clamp-1'>{obj.description != null ? obj.description : ''}</div>
                                      </div>
                                    </div>
                                    <div className='flex items-center justify-center pr-4 sm:pr-9 gap-x-4 sm:gap-x-8'>
                                    </div>
                                  </div>
                                ))
                              }
                              {
                                 categories.length > 3 &&

                              <div onClick={() => viewAll('category')} className='text-sm font-bold cursor-pointer pl-4 pt-4 text-primaryBlue'>View all</div>
                              }
                            </div>
                          </>
                        }
                      </>
                  }
                </>
            }
          </div>
        </div>
      </section>
    </div>
  )
}

export default SearchPage