import axios from "./axios";
const handleCatch = (error) => {
  if (error.response) {

    if (error.response.status === 422) {
      if (error.response.data.errors) {
        const myObject = error.response.data.errors;
        const firstKey = Object.keys(myObject)[0];
        const firstValue = myObject[firstKey];
        throw new Error(firstValue[0]);
      }
      else {
        throw new Error(error.response.data.message);
      }
    } 
     else if(error.response.status === 400){
      if(error.response.data.message.includes('is already a list member')){
       const msg = error.response.data.message.split('. ')[0];
       throw new Error(msg);
      }
     
      else{

        throw new Error(error.response.data.message);
      }
      
    }
    // else if(error.response.status === 401){
    //   localStorage.clear();
    //   window.location.replace(window.location.origin+'/unauthorized')
    //   window.location.reload();
      
    // }
    else if(error.response.status === 401){
      throw new Error(error.response.data.message);
    }
    else {

      throw new Error('An error occurred on the server.');
    }
  } else if (error.request) {

    console.log(error.request);
  } else {

    console.log('Error', error.message);
  }
  throw error;
}

export const LoginUser = async (userData) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/user/login`, userData, {
      headers: myHeaders
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const ThirdPartyLogin = async (userData) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/third-party/login`, userData, {
      headers: myHeaders
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};


export const UserForgetPass = async (userData) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/password/email`, userData, {
      headers: myHeaders
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const SignupUser = async (userData) => {
  try {

    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/user/signup`, userData, {
      headers: myHeaders
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)  
  }
};

export const UserSearch = async (data) => {
  try {
    
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    };

    const response = await axios.post(`/user/user-search`, data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AdvanceUserSearch = async (data) => {
  try {
    
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    };

    const response = await axios.post(`/user/advance-search`, data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const GetDailyTanya = async (data) => {
  try {
    
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    };

    const response = await axios.post(`/user/daily-tanya`, data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const WeeklyParsha = async (data) => {
  try {
    
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    };

    const response = await axios.post(`/user/weekname`, data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const GetCurrentMonth = async () => {
  try {
    
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    };

    const response = await axios.post(`/user/current-month`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const SubmitContactUs = async (data) => {
  try {
    
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    };

    const response = await axios.post(`/user/contact-us`, data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const GetLatestClasses = async () => {
  try {
    
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    };

    const response = await axios.post(`/user/latest-classes`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const GetPopularClasses = async () => {
  try {
    
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    };

    const response = await axios.post(`/user/popular-classes`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const UserSubscribe = async (data) => {
  try {
    
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    };

    const response = await axios.post(`/user/subscribe`, data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const GetDataFromURL = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    if(Admintoken){
      data.is_admin = 1
    }
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    };
    const encodedNode = encodeURIComponent(data.node);

    const updatedData = {
      ...data,
      "encrypted_node": encodedNode
    };
    const response = await axios.post(`/user/url-base`, updatedData, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const GetTagDataFromURL = async (data,page) => {
  try {
    // const Admintoken = localStorage.getItem('adminToken')
    // if(Admintoken){
    //   data.is_admin = 1
    // }
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    };
    // const encodedNode = encodeURIComponent(data.node);

    // const updatedData = {
    //   ...data,
    //   "encrypted_node": encodedNode
    // };
    const response = await axios.post(`/user/tag-click?page=${page+1}`, data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const UserSubmitQuestion = async (data) => {
  try {
    
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      
    };

    const response = await axios.post(`/user/ask-question`, data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export function LogoutUser(token) {
  return new Promise(async (resolve, reject) => {
    try {

      const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      };

      const response = await axios.post(`/user/logout`, null, {
        headers,
      });

      resolve(response.data);
    } catch (error) {
      reject(error);
      console.error("Error:", error);
    }
  });
}

export const UserSideSlider = async () => {
  try {
    // const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      // 'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/user/slider`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const showClassTabData = async () => {
  try {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    const response = await axios.post(`/user/catagory-classes`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const showTeamMember = async () => {
  try {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    };

    const response = await axios.post(`/user/our-team`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const showRecentlyPlayed = async () => {
  const token = localStorage.getItem('accessToken');
  try {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    const response = await axios.post(`/user/playlist`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AddbookMark = async (data) => {
  const token = localStorage.getItem('accessToken');
  try {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    const response = await axios.post(`/user/create-bookmark`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const RemovebookMark = async (data) => {
  const token = localStorage.getItem('accessToken');
  try {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    const response = await axios.post(`/user/delete-bookmark`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const ViewbookMark = async () => {
  const token = localStorage.getItem('accessToken');
  try {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    const response = await axios.post(`/user/view-bookmark`,{}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const EditUserInfo = async (data) => {
  const token = localStorage.getItem('accessToken');
  try {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    const response = await axios.post(`/user/edit-profile`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AddRecentlyPlayed = async (data) => {
  const token = localStorage.getItem('accessToken');
  try {
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    const response = await axios.post(`/user/addplay`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};


export const LoginAdmin = async (userData) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/admin/login`, userData, {
      headers: myHeaders
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const ForgetPassword = async (Data) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/password/email`, Data, {
      headers: myHeaders
    });

    return response.data;
  }
  catch (error) {
   handleCatch(error)
  }
};
export const CodeVerify = async (Data) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/reset/verify`, Data, {
      headers: myHeaders
    });

    return response.data;
  }
  catch (error) {
  handleCatch(error)
  }
};

export const ResetPassword = async (Data) => {
  try {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    const response = await axios.post(`/password/reset`, Data, {
      headers: myHeaders
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AdminListUser = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/list-users?page=${currentPage+1}`, {per_page:itemsPerPage}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AdminBlockUser = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/block-admin-users`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const DeleteUser = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/delete-users`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AdminListRoles = async () => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/role-dropdown`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const EditAdminUser = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/edit-users`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AddAdminUser = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/create-adminuser`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const AdminListRole = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/list-role?page=${currentPage+1}`, {per_page:itemsPerPage}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const PermissionDropdown = async () => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/permission-dropdown`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AddAdminRole = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/add-role`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const UpdateAdminRole = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/update-role`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const DeleteAdminRole = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/delete-role`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const AdminViewPermission = async () => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/view-permission`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AddAdminPermission = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/add-permission`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const AdminListCategories = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/view-category?page=${currentPage+1}`, {per_page:itemsPerPage}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AddAdminCategory = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/add-category`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const UpdateAdminCategory = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/edit-category`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const DeleteAdminCategory = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/delete-category`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const CategoriesDropdown = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/category-dropdown?page=${currentPage+1}`, {per_page:itemsPerPage}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const AdminListTags = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/view-tag?page=${currentPage+1}`, {per_page:itemsPerPage}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AddAdminTag = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/create-tag`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const UpdateAdminTag = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/edit-tag`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const DeleteAdminTag = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/delete-tag`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const TagTypeDropdown = async () => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/dropdown-tagtype`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AddTagType = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/add-tagtype`, data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AdminListTagsType = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/view-tagtype?page=${currentPage+1}`, {per_page:itemsPerPage}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const DeleteAdminTagType = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/delete-tagtype`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const UpdateAdminTagType= async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/edit-tagtype`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const AdminListSeries = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/view-series?page=${currentPage+1}`, {per_page:itemsPerPage}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const CreateAdminseries = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/create-series`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const UpdateAdminseries = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/edit-series`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const DeleteAdminseries = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/delete-series`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AdminTagDropdown = async (currentPage,itemsPerPage,tag_type_ids,tag_type) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };
    
    const response = await axios.post(`/admin/tagdropdown?page=${currentPage+1}`, {per_page:itemsPerPage,tag_type_ids,tag_type}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const MostUsedTags = async () => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/tag-mostused`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const SeriesDropdown = async (currentPage,itemsPerPage,search) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/dropdown-series?page=${currentPage+1}`, {per_page:itemsPerPage,search}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const AdminListClasses = async (currentPage,itemsPerPage,is_page) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/${is_page?'view-page':'view-classes'}?page=${currentPage+1}`, {per_page:itemsPerPage,is_page}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AddAdminClass = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };
  

    const response = await axios.post(`admin/add-classes`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const DeleteAdminClass = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/delete-classes`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const UpdateAdminClass = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/edit-classes`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AddAdminSlider = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/add-slider`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AdminListSlider = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/view-slider?page=${currentPage+1}`, {per_page:itemsPerPage}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
}
export const DeleteAdminSlider = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/delete-slider`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const UpdateAdminSlider = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/edit-slider`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const AdminListMedia = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/view-media?page=1`, {per_page:1000}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AddAdminMedia = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/add-media`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const DeleteAdminMedia = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/delete-media`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const UpdateAdminMedia = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/edit-media`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const AdminListQuestions = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/view-question?page=${currentPage+1}`, {per_page:itemsPerPage}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const DeleteAdminQuestion = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/delete-question`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const SaveAdminMenu = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/create-menu`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const ListAdminMenu = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/view-menu`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const AddAdminTeam = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/add-team`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AdminListTeam = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/view-team?page=${currentPage+1}`, {per_page:itemsPerPage}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
}
export const DeleteAdminTeam = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/delete-team`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const UpdateAdminTeam = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      // 'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/edit-team`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const AdminListComments = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/list-contact-us?page=${currentPage+1}`, {per_page:itemsPerPage}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const DeleteAdminComments = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/delete-contact-us`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const UpdateAdminProfile = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/update-setting`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const AdminHeaderSearch = async (node,search,currentPage,itemsPerPage,is_page) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/${node}?page=${1}`,{search,per_page:itemsPerPage,is_page}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const AdvanceTableSearch = async (node,data,currentPage) => {
  try {
  
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`admin/${node}?page=${currentPage+1}`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
}; 



export const fetch2factor = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };
    const response = await axios.post(`/app/profile/2fa`,data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const SubmitOTP = async (data,key) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')

    // var formdata = new FormData();
    // formdata.append("otp", data.toString());
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`,
      'secret' : key,
    };
    const response = await axios.post(`/app/enable/2fa`,  data, {
      headers

    });



    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};


export const VerifytOTP = async (data,key) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')

    // var formdata = new FormData();
    // formdata.append("otp", data.toString());
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`,
      
    };
    const response = await axios.post(`/verify/otp`,  data, {
      headers

    });



    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const CategoryOrderList = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/order-list`, data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const SeriesOrderList = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/series/order-List`, data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const CategorySetOrderList = async (data) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/set-order`, data, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const CategoriesMenuDropdown = async (currentPage,itemsPerPage) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/category-menu-dropdown?page=${currentPage+1}`, {per_page:itemsPerPage}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};

export const SearchCategoriesMenuDropdown = async (currentPage,itemsPerPage,search) => {
  try {
    const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/admin/category-menu-dropdown?page=${currentPage+1}`, {per_page:itemsPerPage,search}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};
export const GetAudianceOptions = async () => {
  try {
    // const Admintoken = localStorage.getItem('adminToken')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      // 'Authorization': `Bearer ${Admintoken}`
    };

    const response = await axios.post(`/user/get-audiance/subscribe`, {}, {
      headers
    });

    return response.data;
  }
  catch (error) {
    handleCatch(error)
  }
};