import React from 'react'
import { Tab } from '@headlessui/react'
import TabSection1 from './TabSection1'
function EditorSideNav({selected, setSelected,formData,tabtext,setfeatueFile,EditorType}) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }
  return (
    <div className="flex h-full sm:px-0">
    <Tab.Group >
        <Tab.List className="flex flex-col space-y-1">
            <Tab
                className={({ selected }) =>
                    classNames(
                        'w-[88px] rounded-tl-lg py-2 px-4 text-base font-normal leading-5 text-primaryDark',
                        'ring-offset-2 focus:outline-none h-[58px]',
                        selected
                            ? 'bg-[#E1E9F2]'
                            : 'text-primaryDark'
                    )
                }
            >
                <div className='flex flex-col justify-center items-center gap-[2px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path d="M17.6511 3.61744C17.6499 3.61744 17.6487 3.61744 17.6476 3.61744H16.4648V3.21271C16.4712 3.05252 16.3676 2.90856 16.2136 2.86381C15.3925 2.64862 14.5469 2.54072 13.698 2.54282C12.3024 2.54282 10.4009 2.85683 8.99477 4.28734C7.59915 2.84287 5.68366 2.54282 4.29153 2.54282C3.44264 2.54072 2.59707 2.64862 1.77593 2.86381C1.62197 2.90856 1.51835 3.05252 1.52471 3.21271V3.61395H0.348905C0.156222 3.61395 0 3.77018 0 3.96286V15.1069C0 15.2996 0.156222 15.4558 0.348905 15.4558C0.408829 15.4556 0.467707 15.44 0.519868 15.4104C0.554759 15.393 4.04381 13.4845 8.91452 14.7126H8.96685H8.99825C9.02608 14.716 9.05417 14.716 9.08199 14.7126C13.9667 13.474 17.4557 15.379 17.4766 15.4104C17.5846 15.4727 17.7176 15.4727 17.8255 15.4104C17.9328 15.3485 17.9993 15.2343 18 15.1104V3.96635C18 3.77366 17.8438 3.61744 17.6511 3.61744ZM0.69781 14.5521V4.31525H1.53169V13.0658C1.52951 13.2585 1.68395 13.4164 1.87663 13.4186C1.89547 13.4188 1.91431 13.4175 1.93293 13.4147C2.65473 13.3045 3.38346 13.2462 4.11359 13.2402C5.12498 13.2227 6.13213 13.3748 7.09323 13.6903C4.92283 13.4279 2.72133 13.7245 0.69781 14.5521ZM4.11359 12.5459C3.47897 12.5474 2.8451 12.5881 2.21555 12.668V3.48835C2.89486 3.33701 3.58857 3.25977 4.28455 3.25807C5.58945 3.25807 7.3898 3.55813 8.63888 4.9677L8.64935 13.6589C7.92363 13.1844 6.50707 12.5459 4.11359 12.5459ZM9.34716 4.9677C10.5962 3.57208 12.3966 3.25807 13.7015 3.25807C14.3974 3.26012 15.0911 3.33732 15.7705 3.48835V12.668C15.1445 12.5874 14.5141 12.5454 13.8829 12.5424C11.5034 12.5424 10.0833 13.1809 9.34716 13.6554V4.9677ZM17.2987 14.5521C15.2709 13.7222 13.0643 13.4255 10.8893 13.6903C11.855 13.3737 12.8669 13.2215 13.8829 13.2402C14.6176 13.2443 15.3511 13.3015 16.0775 13.4112C16.268 13.4401 16.4459 13.3091 16.4748 13.1186C16.4777 13.1 16.479 13.0811 16.4788 13.0623V4.31525H17.3127L17.2987 14.5521Z" fill="#161E2E" />
                    </svg>
                    {tabtext}
                </div>
            </Tab>
            {
                EditorType!=='page' &&
            <Tab
                className={({ selected }) =>
                    classNames(
                        'w-[88px] rounded-tl-lg py-2 px-4 text-base font-normal leading-5 text-primaryDark',
                        'ring-offset-2 focus:outline-none h-[58px]',
                        selected
                            ? 'bg-[#E1E9F2]'
                            : 'text-primaryDark'
                    )
                }
            >
                <div className='flex flex-col justify-center items-center gap-[2px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <g clipPath="url(#clip0_1312_3905)">
                            <path d="M7.125 18H1.125C0.50475 18 0 17.4953 0 16.875V1.125C0 0.50475 0.50475 0 1.125 0H7.125C7.74525 0 8.25 0.50475 8.25 1.125V16.875C8.25 17.4953 7.74525 18 7.125 18ZM1.125 0.75C0.918 0.75 0.75 0.918 0.75 1.125V16.875C0.75 17.082 0.918 17.25 1.125 17.25H7.125C7.332 17.25 7.5 17.082 7.5 16.875V1.125C7.5 0.918 7.332 0.75 7.125 0.75H1.125Z" fill="black" />
                            <path d="M16.875 4.5H10.875C10.2548 4.5 9.75 3.99525 9.75 3.375V1.125C9.75 0.50475 10.2548 0 10.875 0H16.875C17.4953 0 18 0.50475 18 1.125V3.375C18 3.99525 17.4953 4.5 16.875 4.5ZM10.875 0.75C10.668 0.75 10.5 0.918 10.5 1.125V3.375C10.5 3.582 10.668 3.75 10.875 3.75H16.875C17.082 3.75 17.25 3.582 17.25 3.375V1.125C17.25 0.918 17.082 0.75 16.875 0.75H10.875Z" fill="black" />
                            <path d="M16.875 11.25H10.875C10.2548 11.25 9.75 10.7452 9.75 10.125V7.875C9.75 7.25475 10.2548 6.75 10.875 6.75H16.875C17.4953 6.75 18 7.25475 18 7.875V10.125C18 10.7452 17.4953 11.25 16.875 11.25ZM10.875 7.5C10.668 7.5 10.5 7.668 10.5 7.875V10.125C10.5 10.332 10.668 10.5 10.875 10.5H16.875C17.082 10.5 17.25 10.332 17.25 10.125V7.875C17.25 7.668 17.082 7.5 16.875 7.5H10.875Z" fill="black" />
                            <path d="M16.875 18H10.875C10.2548 18 9.75 17.4952 9.75 16.875V14.625C9.75 14.0048 10.2548 13.5 10.875 13.5H16.875C17.4953 13.5 18 14.0048 18 14.625V16.875C18 17.4952 17.4953 18 16.875 18ZM10.875 14.25C10.668 14.25 10.5 14.418 10.5 14.625V16.875C10.5 17.082 10.668 17.25 10.875 17.25H16.875C17.082 17.25 17.25 17.082 17.25 16.875V14.625C17.25 14.418 17.082 14.25 16.875 14.25H10.875Z" fill="black" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1312_3905">
                                <rect width="18" height="18" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                    Block
                </div>
            </Tab>
            }
        </Tab.List>

        <Tab.Panels className="border-l-[1px] border-[#D2D6DC] w-full">
            <Tab.Panel>
                <TabSection1
                setfeatueFile={setfeatueFile}
                    formData={formData}
                    selected={selected}
                    setSelected={setSelected}
                    tabtext={tabtext}
                    EditorType={EditorType}

                />
            </Tab.Panel>
            <Tab.Panel>
                {/* <BlockTabSection/> */}
            </Tab.Panel>
        </Tab.Panels>
    </Tab.Group>
</div>
  )
}

export default EditorSideNav