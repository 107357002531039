import React, { useState, useEffect } from 'react'
// import { useParams } from 'react-router-dom';
import Header from '../../components/header/Header';
import Banner from '../../components/banner/Banner';
import { AddRecentlyPlayed, GetDataFromURL } from '../../service/service';
import { ColorRing } from 'react-loader-spinner';
import Footer from '../../components/footer/Footer';
import NotFound from '../notfoundPage/NotFound';
import UserCategory from '../userCategoyPages/UserCategory';
import { FacebookIcon, FacebookShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from 'react-share';
import { Helmet } from 'react-helmet';
import CustomAudioPlayer from '../../ReuseableComponents/CustomPlayer';
import CustomVideoPlayer from '../../ReuseableComponents/CustomVideoPlayer';
import { Link } from 'react-router-dom';

function SearchClasses({uniqueName}) {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({});
    const [type, setType] = useState('');
    // const { uniqueName } = useParams();
    const [title, setTitle] = useState("Inside Chassidus | Audio Classes | Chassidus for Everyone")
    useEffect(() => {
        // Scroll to the top when the component mounts
        window.scrollTo(0, 0);
    
        // Optionally, you can also add a listener for navigation changes
        const handleScrollToTop = () => {
          window.scrollTo(0, 0);
        };
    
        // Attach the listener to the "beforeunload" event
        window.addEventListener('beforeunload', handleScrollToTop);
        // Clean up the listener when the component unmounts
        return () => {
          window.removeEventListener('beforeunload', handleScrollToTop);
        };
      }, [uniqueName]);
    useEffect(() => {
     if(uniqueName!==''){
        const data = {
            node: uniqueName
        }
        setLoading(true)
        GetDataFromURL(data).then((result) => {
            setLoading(false)
            if (result.status) {
                setType(result.type)
                setData(result.data)
                if(result.data){

                    setTitle(`${result.data?.title ?? result.data?.name} | Inside Chassidus`)
                }
             
            }
        })
     }
    }, [uniqueName])

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate

    }
    const downloadFile = (fileUrl) => {
        const a = document.createElement('a');
        a.href = fileUrl;
        a.target = '_blank'; // Opens the link in a new tab
        a.click();
    };
    const shareUrl = window.location.href;

    const [lastPlayedMedia, setLastPlayedMedia] = useState({});
    const sendRecentPlayData = (classId, blockId, type) => {
        // Make an API call to add to recent play with class ID, block ID, and type
        const payload = {
            id: classId,
            block_id: blockId,
            type: type,
        };

        // Simulating the API call - Replace this with your actual API call logic
        // console.log('Adding to recent play:', payload);
        AddRecentlyPlayed(payload).then().catch((error) => {
            console.log(error)
        })

        setLastPlayedMedia(payload);
    };

    const handleMediaPlayback = (blockId, blockType) => {
        const classId = data?.id; // Assuming the class ID is available in the data

        if (classId && blockId && blockType) {

            if (
                !(
                    lastPlayedMedia.id === classId &&
                    lastPlayedMedia.block_id === blockId &&
                    lastPlayedMedia.type === blockType
                )
            ) {
                // If current media is different from the last played, trigger API call
                sendRecentPlayData(classId, blockId, blockType);
            }
        }
    };
    return (
        <>
            <Header />
            <Banner title={data?.title ?? data?.name} description={data?.description} />
            <Helmet>
                <title>{title}</title>
                {/* <meta name="description" content='classes' /> */}
            </Helmet>
            {
                loading ?
                    <div className='flex justify-center min-h-[50vh]'>
                        <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                            colors={['#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC', '#D2D6DC']}
                        />
                    </div>
                    :
                    <div className='min-h-[50vh]'>
                        {
                            type === 'class' &&
                            <>
                                {
                                    data ?
                                        <section className='px-2 xs:px-5 pt-6 pb-[38px] md:pt-8 md:pb-[100px]'>
                                            <div className='2xl:max-w-2xl xl:max-w-xl lg:max-w-lg md:max-w-md sm:max-w-sm mx-auto text-primaryDark'>
                                                <div className='text-primaryDark text-sm font-semibold opacity-50 mb-3'>
                                                    {data?.created_at && `Posted : ${formatDate(data?.is_publish_date ?? data?.created_at)}`}
                                                </div>
                                                {
                                                    data?.block?.map((obj) => (
                                                        <div key={obj.id} className='mb-10'>
                                                            {
                                                                obj.type === 'Paragraph' &&
                                                                <div className='text-primaryDark text-lg font-semibold'>
                                                                    {obj.description}
                                                                </div>
                                                            }
                                                            {
                                                                obj.type === 'Video' &&
                                                                <div>
                                                                    {/* <video className="rounded-lg w-full bg-whiteGrey" controls
                                                                        onPlay={() => handleMediaPlayback(obj.id, 'Video')}
                                                                    >
                                                                        <source src={obj.url} type="video/mp4" />
                                                                    </video> */}
                                                                    <CustomVideoPlayer
                                                                        videoUrl={obj.url}
                                                                        onPlay={() => handleMediaPlayback(obj.id, 'Video')}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                obj.type === 'Audio' &&
                                                                <div className='bg-[#f1f3f4] rounded-lg'>
                                                                    {/* <audio controls className="w-full"
                                                                        onPlay={() => handleMediaPlayback(obj.id, 'Audio')}
                                                                    >
                                                                        <source src={obj.url} type="audio/ogg" />
                                                                        <source src={obj.url} type="audio/mpeg" />
                                                                        Your browser does not support the audio tag.
                                                                    </audio> */}
                                                                    <CustomAudioPlayer
                                                                        audioUrl={obj.url}
                                                                        onPlay={() => handleMediaPlayback(obj.id, 'Audio')}
                                                                    />
                                                                </div>
                                                            }
                                                            {
                                                                obj.type === 'Documents' &&

                                                                <>


                                                                    <object data={obj.url} type="application/pdf" width="100%" height="500px">
                                                                        <p>Unable to display PDF file. <a href={obj.url}>Download</a> instead.</p>
                                                                    </object>
                                                                    <div className='flex justify-center py-4'>
                                                                        <button className="flex gap-x-2 w-[173px] h-11 items-center justify-center rounded-3xl border-primaryBlue border-[1px] hover:bg-opacity-[0.9]  text-sm md:text-base font-semibold leading-7 text-primaryBlue shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                                                            onClick={() => downloadFile(obj.url)}>
                                                                            Download pdf
                                                                            <span>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                                    <path d="M12.3798 17.92C12.5026 17.8724 12.6147 17.801 12.7098 17.71L17.7098 12.71C17.8031 12.6168 17.877 12.5061 17.9275 12.3842C17.9779 12.2624 18.0039 12.1319 18.0039 12C18.0039 11.7337 17.8981 11.4783 17.7098 11.29C17.6166 11.1968 17.5059 11.1228 17.3841 11.0723C17.2622 11.0219 17.1317 10.9959 16.9998 10.9959C16.7335 10.9959 16.4781 11.1017 16.2898 11.29L12.9998 14.59L12.9998 7C12.9998 6.73478 12.8945 6.48043 12.7069 6.29289C12.5194 6.10536 12.265 6 11.9998 6C11.7346 6 11.4802 6.10536 11.2927 6.29289C11.1052 6.48043 10.9998 6.73478 10.9998 7L10.9998 14.59L7.70981 11.29C7.61685 11.1963 7.50625 11.1219 7.38439 11.0711C7.26253 11.0203 7.13183 10.9942 6.99981 10.9942C6.8678 10.9942 6.7371 11.0203 6.61524 11.0711C6.49338 11.1219 6.38278 11.1963 6.28982 11.29C6.19609 11.383 6.12169 11.4936 6.07092 11.6154C6.02015 11.7373 5.99402 11.868 5.99402 12C5.99402 12.132 6.02015 12.2627 6.07092 12.3846C6.12169 12.5064 6.19609 12.617 6.28982 12.71L11.2898 17.71C11.3849 17.801 11.4971 17.8724 11.6198 17.92C11.8633 18.02 12.1364 18.02 12.3798 17.92Z" fill="#186CCE" />
                                                                                </svg>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                obj.type === 'Images' &&

                                                                <>
                                                                    <div className='flex justify-center rounded-lg'>
                                                                        <img src={obj.url} alt="class" />
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                obj.type === 'Custom HTML' &&

                                                                <>
                                                                    <div dangerouslySetInnerHTML={{ __html: obj.html }} />
                                                                </>
                                                            }
                                                        </div>
                                                    ))
                                                }

                                                {
                                                    data?.is_page !== 1 &&
                                                    <>
                                                        {
                                                            data?.tags?.length > 0 &&
                                                            <div className='flex flex-wrap gap-3 mt-6 mb-4'>
                                                                {
                                                                    data?.tags?.map((tag) => (
                                                                        <Link to={`tag/${tag?.slug}`} className='py-2 px-6 bg-primaryDark rounded-md text-base text-whiteGrey'>
                                                                            {tag.name}
                                                                        </Link>
                                                                    ))
                                                                }
                                                            </div>


                                                        }

                                                        <div className='font-bold text-xl'>
                                                            Share this class!
                                                        </div>
                                                        <div className='flex items-center gap-x-[27px] mt-3'>
                                                            <WhatsappShareButton
                                                                url={shareUrl}
                                                                separator=":: "
                                                                className="Demo__some-network__share-button"
                                                            >
                                                                <WhatsappIcon size={32} round />
                                                            </WhatsappShareButton>
                                                            <FacebookShareButton
                                                                url={shareUrl}
                                                                className="Demo__some-network__share-button"
                                                            >
                                                                <FacebookIcon size={32} round />
                                                            </FacebookShareButton>

                                                            <TwitterShareButton
                                                                url={shareUrl}
                                                                className="Demo__some-network__share-button"
                                                            >
                                                                <XIcon size={32} round />
                                                            </TwitterShareButton>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </section>
                                        :
                                        <NotFound />
                                }
                            </>
                        }
                        {
                            type === 'category' &&
                            <UserCategory data={data} />
                        }
                        {
                            type === null &&
                            <NotFound />
                        }
                    </div>
            }
            <Footer />
        </>
    )
}

export default SearchClasses